import styled, { css } from "styled-components";

import { ToastV2Props } from "@sellernote/_shared/src/headlessComponents/useToast";
import { mobile } from "@sellernote/_shared/src/styles/functions";

import { COLOR, TEXT_COLOR } from "../../styles/colors";
import { setFontStyle } from "../../styles/typography";

const toastContainer = styled.div`
  width: 400px;
  height: 40px;
  ${setFontStyle("SubHead3")}
  padding: 10px 0px 10px 16px;
  border-radius: 4px;
  display: flex;
  user-select: none;

  &:not(:first-child) {
    margin-top: 16px;

    ${mobile(css`
      margin-top: 0;
      margin-bottom: 8px;
    `)}
  }

  ${mobile(css`
    width: 328px;
  `)}

  ${({ status }: Pick<ToastV2Props, "status">) => {
    if (status === "positive")
      return css`
        background-color: ${COLOR.success_400};
      `;

    if (status === "negative")
      return css`
        background-color: ${COLOR.error_400};
      `;

    return css`
      background-color: ${COLOR.grayScale_800};
    `;
  }}
`;

const iconContainer = styled.div`
  display: flex;
  align-items: center;
`;

const messageContainer = styled.div<Pick<ToastV2Props, "status">>`
  margin-left: ${({ status }) => (status !== "neutral" ? "8px" : "0px")};
  color: ${TEXT_COLOR.white_1};
  max-width: 352px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const buttonContainer = styled.div<{ showsClearIcon: boolean }>`
  margin-left: auto;
  margin-right: 8px;
  background: none;
  position: relative;
  white-space: nowrap;
  display: flex;
  align-items: center;

  .toast-text-button {
    right: ${({ showsClearIcon }) => (showsClearIcon ? "40px" : "16px")};
    position: absolute;
  }

  .toast-clear-icon {
    cursor: pointer;
  }
`;

export default {
  toastContainer,
  iconContainer,
  messageContainer,
  buttonContainer,
};
