import { Dispatch, SetStateAction, useMemo } from "react";
import { useQueryClient } from "react-query";
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from "recoil";

import { COMPLETE_PACKING_REQ } from "@sellernote/_shared/src/api-interfaces/boful-api/packing";
import {
  ADD_INVOICE_REQ,
  ADD_INVOICE_RES,
  ADD_PARCEL_INFO_REQ,
  ADD_PARCEL_INFO_REQ_PATH_PARAMS,
  ADD_SHIPPING_ADMIN_MEMO_REQ,
  ADD_SHIPPING_ADMIN_MEMO_REQ_PATH_PARAMS,
  ADD_SHIPPING_ITEM_REQ,
  ADD_SHIPPING_ITEM_RES,
  CANCEL_SHIPPING_FOR_ADMIN_REQ,
  CANCEL_SHIPPING_FOR_ADMIN_RES,
  CHANGE_PICKING_LOCATION_REQ,
  CHANGE_SHIPPING_ITEMS_LOCATIONS_REQ,
  CHANGE_SHIPPING_ITEMS_LOCATIONS_RES,
  CHECK_EXISTING_SHIPPING_REQ,
  CHECK_EXISTING_SHIPPING_RES,
  COMPLETE_SHIPMENT_REQ,
  COMPLETE_SHIPMENT_RES,
  CREATE_FORCE_PICKING_REQ_PATH_PARAMS,
  CREATE_FORCE_PICKING_RES,
  DOWNLOAD_SHIPPING_EXCEL_LIST_REQ,
  EDIT_CUSTOMER_DETAIL_REQ,
  EDIT_CUSTOMER_DETAIL_RES,
  EDIT_DELIVERY_INFO_REQ,
  FORCE_SHIPPING_REQ,
  FORCE_SHIPPING_RES,
  GET_ADMIN_DETAIL_REQ_PATH_PARAMS,
  GET_ADMIN_DETAIL_RES,
  GET_ADMIN_SHIPPING_LIST_REQ,
  GET_ADMIN_SHIPPING_LIST_RES,
  GET_COUNT_LIST_OF_SHIPPING_RES,
  GET_DETAIL_V2_REQ,
  GET_DETAIL_V2_RES,
  GET_INVOICE_PRINTING_DATA_REQ,
  GET_INVOICE_PRINTING_DATA_RES,
  GET_KYOUNGDONG_SHIPPING_FREIGHT_LIST_REQ,
  GET_KYOUNGDONG_SHIPPING_FREIGHT_LIST_RES,
  GET_SHIPMENT_PARCEL_DELAY_REQ,
  GET_SHIPMENT_PARCEL_DONE_REQ,
  GET_SHIPMENT_PARCEL_ORDER_REQ,
  GET_SHIPMENT_PARCEL_TODAY_REQ,
  GET_SHIPMENT_TRUCK_DELAY_REQ,
  GET_SHIPMENT_TRUCK_DONE_REQ,
  GET_SHIPMENT_TRUCK_TODAY_REQ,
  GET_SHIPPER_LIST_REQ,
  GET_SHIPPER_LIST_RES,
  GET_SHIPPING_DETAIL_REQ,
  GET_SHIPPING_DETAIL_RES,
  GET_SHIPPING_LIST_BY_SKU_ID_REQ_PATH_PARAMS,
  GET_SHIPPING_LIST_BY_SKU_ID_RES,
  GET_SHIPPING_REQ,
  GET_SHIPPING_SEARCH_SUGGESTION_LIST_REQ,
  GET_SHIPPING_SEARCH_SUGGESTION_LIST_RES,
  GET_SHIPPING_SKU_LIST_REQ,
  GET_SHIPPING_SKU_LIST_RES,
  GET_SHIPPING_SUMMARY_SUMMARY_REQ_PATH_PARAMS,
  GET_SHIPPING_SUMMARY_SUMMARY_RES,
  GET_TRUCK_DELIVERY_DETAIL_RES,
  INVOICE_NUMBERING_BY_PARCEL_COMPANY_REQ,
  INVOICE_NUMBERING_REQ,
  INVOICE_NUMBERING_RES,
  REFINE_ADDRESS_REQ,
  REFINE_ADDRESS_RES,
  REORDER_SHIPPING_REQ,
  REORDER_SHIPPING_RES,
  REPORT_PROBLEM_REQ,
  REPORT_PROBLEM_RES,
  UPDATE_PARCEL_DELIVERY_DETAIL_REQ,
  UPDATE_SHIPPER_MEMO_REQ,
  UPDATE_SHIPPER_MEMO_REQ_PATH_PARAMS,
  UPDATE_SHIPPER_MEMO_RES,
  UPDATE_SHIPPING_DETAIL_V2_REQ,
  UPDATE_SHIPPING_DETAIL_V2_RES,
  UPDATE_STATUS_WHEN_PRINTING_TRANSACTION_STATEMENT_REQ,
  UPDATE_STATUS_WHEN_PRINTING_TRANSACTION_STATEMENT_RES,
  UPDATE_TRUCK_DELIVERY_DETAIL_REQ,
  UPLOAD_DOMESTIC_SHIPPING_EXCEL_REQ,
  UPLOAD_DOMESTIC_SHIPPING_EXCEL_RES,
  UPLOAD_OVERSEAS_SHIPPING_EXCEL_REQ,
  UPLOAD_OVERSEAS_SHIPPING_EXCEL_RES,
  VALIDATE_DOMESTIC_SHIPPING_EXCEL_REQ,
  VALIDATE_DOMESTIC_SHIPPING_EXCEL_RES,
  VALIDATE_KYOUNGDONG_SHIPPING_EXCEL_INVOICE_LIST_RES,
  VALIDATE_OVERSEAS_SHIPPING_EXCEL_REQ,
  VALIDATE_OVERSEAS_SHIPPING_EXCEL_RES,
  VALIDATE_SHIPPING_EXCEL_INVOICE_LIST_RES,
} from "@sellernote/_shared/src/api-interfaces/boful-api/shipping";
import {
  QueryResponseHandlerCustomMessage,
  QueryResponseHandlerFailureModalInfo,
  QueryResponseHandlerSuccessModalInfo,
} from "@sellernote/_shared/src/components/QueryResponseHandler";
import {
  MutationSideEffectType,
  useAppMutation,
  useAppQueryWithQueryKeyFactory,
} from "@sellernote/_shared/src/services/query";
import { COLOR } from "@sellernote/_shared/src/stylesToMoveToV1/constants";
import { PackingInvoice } from "@sellernote/_shared/src/types/fulfillment/packing";
import {
  CompletedPickingList,
  KyoungDongShippingInvoiceExcelItem,
  ParcelCompanyType,
  PickingSKUInfoForScanning,
  ShipmentParcelSummary,
  ShipmentStatusType,
  ShipmentSummary,
  ShippingDeliveryType,
  ShippingInvoiceExcelItem,
  ShippingItemDetail,
  ShippingList,
  ShippingListItem,
  ShippingShipmentList,
  ShippingShipmentTruckPackings,
  TruckCompanyType,
  UnSetRowInfo,
} from "@sellernote/_shared/src/types/fulfillment/shipping";

import {
  CommonListRequest,
  ResponseFailureInfo,
} from "../../types/common/common";
import { ScanType } from "../../types/fulfillment/scan";
import {
  getErrorMessageAsE4000,
  getErrorMessageAsE4009,
} from "../../utils/fulfillment/fulfillment";

import { RESULT_RES } from "../../api-interfaces/boful-api/common";
import { FULFILLMENT_COMMON_ATOMS } from "../../states/fulfillment/common";
import {
  FULFILLMENT_SHIPPING_ATOMS,
  FULFILLMENT_SHIPPING_SELECTORS,
} from "../../states/fulfillment/shipping";

export const SHIPPING_QUERY_KEY_GEN = {
  all: () => [{ scope: "fulfillment/SHIPPING_QUERY" }] as const,

  getRefineAddress: (params: REFINE_ADDRESS_REQ) =>
    [
      {
        ...SHIPPING_QUERY_KEY_GEN.all()[0],
        ...params,
        entity: "getRefineAddress",
      },
    ] as const,

  userShippingV2: () =>
    [
      {
        ...SHIPPING_QUERY_KEY_GEN.all()[0],
        subScope: "userShippingV2",
      },
    ] as const,
  getDetailV2: (params: GET_DETAIL_V2_REQ) =>
    [
      {
        ...SHIPPING_QUERY_KEY_GEN.userShippingV2()[0],
        ...params,
        entity: "detailV2",
      },
    ] as const,
  getShipperList: (params: GET_SHIPPER_LIST_REQ) =>
    [
      {
        // TODO: getDetailV2 없어지면 수정 필요
        ...SHIPPING_QUERY_KEY_GEN.userShippingV2()[0],
        ...params,
        entity: "getShipperList",
      },
    ] as const,
  getShippingSummary: (params: GET_SHIPPING_SUMMARY_SUMMARY_REQ_PATH_PARAMS) =>
    [
      {
        ...SHIPPING_QUERY_KEY_GEN.userShippingV2()[0],
        ...params,
        entity: "getShippingSummary",
      },
    ] as const,

  getShippingCount: () =>
    [
      {
        ...SHIPPING_QUERY_KEY_GEN.all()[0],
        entity: "shippingCount",
      },
    ] as const,

  getPDAShippingList: (params: GET_SHIPPING_REQ) =>
    [
      {
        ...SHIPPING_QUERY_KEY_GEN.all()[0],
        ...params,
        entity: "getPDAshippingList",
      },
    ] as const,

  adminShipping: () =>
    [
      {
        ...SHIPPING_QUERY_KEY_GEN.all()[0],
        subScope: "adminShipping",
      },
    ] as const,
  getAdminDetail: (params: GET_ADMIN_DETAIL_REQ_PATH_PARAMS) =>
    [
      {
        ...SHIPPING_QUERY_KEY_GEN.adminShipping()[0],
        ...params,
        entity: "adminDetail",
      },
    ] as const,
  getAdminShippingList: (params: GET_ADMIN_SHIPPING_LIST_REQ) =>
    [
      {
        ...SHIPPING_QUERY_KEY_GEN.adminShipping()[0],
        ...params,
        entity: "adminShippingList",
      },
    ] as const,

  completedPickingList: () =>
    [
      { ...SHIPPING_QUERY_KEY_GEN.all()[0], subScope: "completedPickingList" },
    ] as const,
  getCompletedPickingList: (params: CommonListRequest) =>
    [
      {
        ...SHIPPING_QUERY_KEY_GEN.completedPickingList()[0],
        ...params,
      },
    ] as const,

  shipmentSummary: () =>
    [
      { ...SHIPPING_QUERY_KEY_GEN.all()[0], subScope: "shipmentSummary" },
    ] as const,
  getShipmentSummary: (
    params:
      | {
          parcelCompany: ParcelCompanyType;
        }
      | {
          truckCompany: TruckCompanyType;
        }
  ) =>
    [
      {
        ...SHIPPING_QUERY_KEY_GEN.shipmentSummary()[0],
        ...params,
      },
    ] as const,

  shipmentToday: () =>
    [
      { ...SHIPPING_QUERY_KEY_GEN.all()[0], subScope: "shipmentToday" },
    ] as const,
  getShipmentToday: (
    params: GET_SHIPMENT_PARCEL_TODAY_REQ | GET_SHIPMENT_TRUCK_TODAY_REQ
  ) =>
    [
      {
        ...SHIPPING_QUERY_KEY_GEN.shipmentToday()[0],
        ...params,
      },
    ] as const,

  shipmentDone: () =>
    [{ ...SHIPPING_QUERY_KEY_GEN.all()[0], subScope: "shipmentDone" }] as const,
  getShipmentDone: (
    params: GET_SHIPMENT_PARCEL_DONE_REQ | GET_SHIPMENT_TRUCK_DONE_REQ
  ) =>
    [
      {
        ...SHIPPING_QUERY_KEY_GEN.shipmentDone()[0],
        ...params,
      },
    ] as const,

  shipmentDelay: () =>
    [
      { ...SHIPPING_QUERY_KEY_GEN.all()[0], subScope: "shipmentDelay" },
    ] as const,
  getShipmentDelay: (
    params: GET_SHIPMENT_PARCEL_DELAY_REQ | GET_SHIPMENT_TRUCK_DELAY_REQ
  ) =>
    [
      {
        ...SHIPPING_QUERY_KEY_GEN.shipmentDelay()[0],
        ...params,
      },
    ] as const,

  shipmentParcelOrder: () =>
    [
      { ...SHIPPING_QUERY_KEY_GEN.all()[0], subScope: "shipmentParcelOrder" },
    ] as const,
  getShipmentParcelOrder: (params: GET_SHIPMENT_PARCEL_ORDER_REQ) =>
    [
      {
        ...SHIPPING_QUERY_KEY_GEN.shipmentParcelOrder()[0],
        ...params,
      },
    ] as const,

  shipmentTruckPackings: () =>
    [
      { ...SHIPPING_QUERY_KEY_GEN.all()[0], subScope: "shipmentTruckPackings" },
    ] as const,
  getShipmentTruckPackings: ({ shippingId }: { shippingId: number }) =>
    [
      {
        ...SHIPPING_QUERY_KEY_GEN.shipmentTruckPackings()[0],
        shippingId,
      },
    ] as const,

  getTruckDeliveryDetail: ({ shippingId }: { shippingId: number }) =>
    [
      {
        ...SHIPPING_QUERY_KEY_GEN.all()[0],
        shippingId,
        entity: "truckDeliveryDetail",
      },
    ] as const,

  getShippingDetail: ({ id }: GET_SHIPPING_DETAIL_REQ) =>
    [
      {
        ...SHIPPING_QUERY_KEY_GEN.all()[0],
        id,
        entity: "shippingDetail",
      },
    ] as const,

  getShippingListBySkuId: (
    params: Partial<GET_SHIPPING_LIST_BY_SKU_ID_REQ_PATH_PARAMS>
  ) =>
    [
      {
        ...SHIPPING_QUERY_KEY_GEN.all()[0],
        ...params,
        entity: "shippingListBySkuId",
      },
    ] as const,

  getShippingSearchSuggestionList: (
    params: GET_SHIPPING_SEARCH_SUGGESTION_LIST_REQ
  ) =>
    [
      {
        ...SHIPPING_QUERY_KEY_GEN.all()[0],
        ...params,
        entity: "shippingSearchSuggestionList",
      },
    ] as const,

  getKyoungdongShippingFreightList: ({
    ids,
  }: GET_KYOUNGDONG_SHIPPING_FREIGHT_LIST_REQ) =>
    [
      {
        ...SHIPPING_QUERY_KEY_GEN.all()[0],
        ids,
        entity: "kyoungdongShippingFreightList",
      },
    ] as const,

  checkExistingShipping: (params: CHECK_EXISTING_SHIPPING_REQ) =>
    [
      {
        ...SHIPPING_QUERY_KEY_GEN.all()[0],
        ...params,
        entity: "checkExistingShipping",
      },
    ] as const,

  getShippingSkuList: (params: GET_SHIPPING_SKU_LIST_REQ) =>
    [
      {
        ...SHIPPING_QUERY_KEY_GEN.all()[0],
        ...params,
        entity: "getShippingSkuList",
      },
    ] as const,
};

function useGetDetailV2({
  shippingId,
  enabled = true,
  onSuccess,
}: GET_DETAIL_V2_REQ & {
  enabled?: boolean;
  onSuccess?: (data: GET_DETAIL_V2_RES) => void;
}) {
  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<typeof SHIPPING_QUERY_KEY_GEN.getDetailV2>,
    GET_DETAIL_V2_RES
  >({
    queryKey: SHIPPING_QUERY_KEY_GEN.getDetailV2({ shippingId }),
    requestOptions: {
      method: "get",
      path: `/shipper/${shippingId}`,
      apiType: "BofulDefault",
    },
    onSuccess,
    enabled,

    failureModalInfo: {
      customizeMessage: (failureInfo) => {
        if (failureInfo?.errorCode === "E4100")
          return {
            messageType: "titleOnly",
            title: "일치하는 출고 정보가 존재하지 않습니다.",
          };

        return {
          title: "의뢰 조회에 실패했습니다.",
        };
      },
    },
  });

  return { ...queryResult };
}

function useUpdateShippingDetailV2({ shippingId }: { shippingId: number }) {
  const mutation = useAppMutation<
    UPDATE_SHIPPING_DETAIL_V2_REQ,
    UPDATE_SHIPPING_DETAIL_V2_RES
  >({
    requestOptions: {
      method: "patch",
      path: `/shipper/${shippingId}`,
      apiType: "BofulDefault",
    },

    failureModalInfo: {
      customizeMessage: (failureInfo) => {
        if (failureInfo?.errorCode === "E4301")
          return {
            messageType: "titleOnly",
            title: "요청한 일자에는 배송이 진행될 수 없습니다.",
          };

        if (failureInfo?.errorCode === "E4100")
          return {
            messageType: "titleOnly",
            title: "일치하는 출고 정보가 존재하지 않습니다.",
          };

        if (failureInfo?.errorCode === "E5010")
          return {
            messageType: "titleOnly",
            title: "재고가 존재하지 않습니다.",
          };

        if (failureInfo?.errorCode === "E5011")
          return {
            messageType: "titleOnly",
            title: "출고 물품 정보와 맞는 재고가 존재하지 않습니다.",
          };

        if (failureInfo?.errorCode === "E5015")
          return {
            messageType: "titleOnly",
            title: "현재 재고가 요청한 재고보다 적습니다.",
          };

        return {
          title: "출고 수정에 실패했습니다.",
        };
      },
    },
  });

  return { ...mutation };
}

function useRefineAddress({
  address,
  enabled = true,
}: {
  address: string;
  enabled?: boolean;
}) {
  const isValidated = !!address;

  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<typeof SHIPPING_QUERY_KEY_GEN.getRefineAddress>,
    REFINE_ADDRESS_RES
  >({
    queryKey: SHIPPING_QUERY_KEY_GEN.getRefineAddress({ address }),
    requestOptions: {
      method: "get",
      path: `parcel/refine/address`,
      apiType: "BofulDefault",
      params: { address },
    },

    enabled: isValidated && enabled,

    failureModalInfo: {
      customizeMessage: () => ({
        title: "주소 분석 중에 오류가 발생했습니다.",
      }),
    },
  });

  return { ...queryResult };
}

/**
 * 모든 상품이 '포장불필요'인 경우 피킹완료 시 패킹완료까지 해주기 위해서 사용(피킹완료 API 수정 전까지 임시로 사용함)
 */
function useStarPacking({
  resetPickingState,
}: {
  resetPickingState: () => void;
}) {
  const pickingList = useRecoilValue(
    FULFILLMENT_SHIPPING_SELECTORS.PICKING_LIST
  );
  const { invoiceNo } = useRecoilValue(
    FULFILLMENT_SHIPPING_ATOMS.SCANNED_PICKING_INVOICE
  );

  const {
    mutate: completePacking,
    ResponseHandler: ResponseHandlerOfCompletingPacking,
  } = SHIPPING_QUERY.useCompletePacking({
    resetPickingState,
  });

  const mutation = useAppMutation<void, PackingInvoice>({
    requestOptions: {
      method: "patch",
      path: `/shipping/worker/packing/start/${invoiceNo}`,
      apiType: "BofulDefault",
    },

    failureModalInfo: {
      customizeMessage: () => ({
        title: "패킹시작 요청 처리 중에 오류가 발생했습니다.",
      }),
    },

    onSuccess: () => {
      const getItemsToCompletePacking = ({
        pickingList,
      }: {
        pickingList: PickingSKUInfoForScanning[];
      }) =>
        pickingList.reduce<{ skuId: number; qty: number }[]>((acc, v) => {
          const indexOfDuplicateSKUId = acc.findIndex(
            (e) => e.skuId === v.skuId
          );

          if (indexOfDuplicateSKUId === -1) {
            return [...acc, { skuId: v.skuId, qty: v.quantity }];
          } else {
            const item = acc[indexOfDuplicateSKUId];

            item.qty += v.quantity;

            return acc;
          }
        }, []);

      completePacking({
        boxes: [
          {
            invoiceNo,
            packings: [
              {
                items: getItemsToCompletePacking({ pickingList }),
              },
            ],
          },
        ],
      });
    },
  });

  return { ...mutation, ResponseHandlerOfCompletingPacking };
}

/**
 * 모든 상품이 '포장불필요'인 경우 피킹완료 시 패킹완료까지 해주기 위해서 사용(피킹완료 API 수정 전까지 임시로 사용함)
 */
function useCompletePacking({
  resetPickingState,
}: {
  resetPickingState: () => void;
}) {
  const { invoiceNo, shippingId } = useRecoilValue(
    FULFILLMENT_SHIPPING_ATOMS.SCANNED_PICKING_INVOICE
  );
  const resetScannedPickingInvoice = useResetRecoilState(
    FULFILLMENT_SHIPPING_ATOMS.SCANNED_PICKING_INVOICE
  );
  const setCanNotLeavePage = useSetRecoilState(
    FULFILLMENT_COMMON_ATOMS.CAN_NOT_LEAVE_PAGE
  );

  const queryClient = useQueryClient();

  const mutation = useAppMutation<COMPLETE_PACKING_REQ, PackingInvoice>({
    requestOptions: {
      method: "patch",
      path: `/shipping/worker/packing/complete/${shippingId}`,
      apiType: "BofulDefault",
    },

    successModalInfo: {
      handleConfirmSuccess: (initQuery) => {
        initQuery();

        resetScannedPickingInvoice();
        setCanNotLeavePage(false);
        queryClient.invalidateQueries(
          SHIPPING_QUERY_KEY_GEN.completedPickingList()
        );

        resetPickingState();
      },
      customizeMessage: () => ({
        messageType: "titleAndBody",
        title: `${invoiceNo} (송장(QR)번호)`,
        body: (
          <>
            피킹이 완료되었습니다.
            <br />
            <div style={{ color: COLOR.pointWarning }}>
              해당 송장은 패킹이 불필요한 송장입니다.
            </div>
          </>
        ),
      }),
    },

    failureModalInfo: {
      customizeMessage: () => ({
        title: "패킹마감 요청 처리 중에 오류가 발생했습니다.",
      }),
    },
  });

  return { ...mutation };
}

function useGetShippingCount({ enabled }: { enabled: boolean }) {
  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<typeof SHIPPING_QUERY_KEY_GEN.getShippingCount>,
    GET_COUNT_LIST_OF_SHIPPING_RES
  >({
    queryKey: SHIPPING_QUERY_KEY_GEN.getShippingCount(),
    requestOptions: {
      method: "get",
      path: `/shipping/worker/pda/list/count`,
      apiType: "BofulDefault",
    },

    enabled,

    failureModalInfo: {
      customizeMessage: () => ({
        title: `담당자에게 배정된 의뢰수 조회 중에 오류가 발생했습니다.`,
      }),
    },
  });

  const shippingCountByTitle: { [key: string]: number } = {
    의뢰확인: queryResult.data?.waiting ?? 0,
    피킹: queryResult.data?.picking ?? 0,
    출하: queryResult.data?.shipment ?? 0,
  };

  return { ...queryResult, shippingCountByTitle };
}

function useGetShipperList(params: GET_SHIPPER_LIST_REQ) {
  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<typeof SHIPPING_QUERY_KEY_GEN.getShipperList>,
    GET_SHIPPER_LIST_RES
  >({
    queryKey: SHIPPING_QUERY_KEY_GEN.getShipperList(params),
    requestOptions: {
      method: "get",
      path: `/shipper/v2`,
      apiType: "BofulDefault",
      params,
    },

    failureModalInfo: {
      customizeMessage: (failureInfo) => {
        if (failureInfo?.errorCode === "E4200")
          return {
            messageType: "titleOnly",
            title: "검색 조건과 일치하지 않습니다.",
          };

        if (failureInfo?.errorCode === "E4201")
          return {
            messageType: "titleOnly",
            title: "같이 검색할 수 없는 조건입니다.",
          };

        if (failureInfo?.errorCode === "E4202")
          return {
            messageType: "titleOnly",
            title: "상품 검색은 완전 검색만 가능합니다.",
          };

        return {
          title: "출고 리스트 조회에 실패했습니다.",
        };
      },
    },
  });

  return queryResult;
}

/**
 * 쉽다 웹에서만 사용되고 보풀 어드민에서는 사용되지 않음
 * TODO: 새로 만들어진 출고 API V2, 출고 반품 API 모두 수정 후 기존 API는 사라질 예정
 */
function useAddShippingItemV2() {
  const queryClient = useQueryClient();

  const mutation = useAppMutation<ADD_SHIPPING_ITEM_REQ, ADD_SHIPPING_ITEM_RES>(
    {
      requestOptions: {
        method: "post",
        path: `/shipper`,
        apiType: "BofulDefault",
      },

      onSuccess: () => {
        queryClient.invalidateQueries(
          SHIPPING_QUERY_KEY_GEN.getShipperList({})
        );
      },

      failureModalInfo: {
        customizeMessage: (failureInfo) => {
          if (failureInfo?.errorCode === "E4301")
            return {
              messageType: "titleOnly",
              title: "요청한 일자에는 배송이 진행될 수 없습니다.",
            };

          if (failureInfo?.errorCode === "E4302")
            return {
              messageType: "titleOnly",
              title: "비정상적인 출고 요청입니다.",
            };

          if (failureInfo?.errorCode === "E4300")
            return {
              messageType: "titleOnly",
              title: "존재하지 않는 센터 ID입니다.",
            };

          if (failureInfo?.errorCode === "E5010")
            return {
              messageType: "titleOnly",
              title: "재고가 존재하지 않습니다.",
            };

          if (failureInfo?.errorCode === "E5015")
            return {
              messageType: "titleOnly",
              title: "현재 재고가 요청한 재고보다 적습니다.",
            };

          return {
            title: "출고 등록에 실패했습니다.",
          };
        },
      },
    }
  );

  return { ...mutation };
}

function useGetAdminDetail({
  shippingId,
  enabled = true,
  onSuccess,
  onError,
}: GET_ADMIN_DETAIL_REQ_PATH_PARAMS & {
  enabled?: boolean;
  onSuccess?: (data: GET_ADMIN_DETAIL_RES) => void;
  onError?: (
    error: ResponseFailureInfo | undefined,
    hideFailureModal: () => void
  ) => void;
}) {
  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<typeof SHIPPING_QUERY_KEY_GEN.getAdminDetail>,
    GET_ADMIN_DETAIL_RES
  >({
    queryKey: SHIPPING_QUERY_KEY_GEN.getAdminDetail({ shippingId }),
    requestOptions: {
      method: "get",
      path: `/shipping/admin/new/${shippingId}`,
      apiType: "BofulDefault",
    },

    failureModalInfo: {
      customizeMessage: () => ({
        title: `출고 상세 정보 조회 중에 오류가 발생했습니다.`,
      }),
    },

    enabled,

    onSuccess,
    onError,
  });

  return { ...queryResult };
}

function useGetAdminShippingList({
  enabled,
  onSuccess,
  ...params
}: GET_ADMIN_SHIPPING_LIST_REQ & {
  enabled?: boolean;
  onSuccess?: (data: GET_ADMIN_SHIPPING_LIST_RES) => void;
}) {
  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<typeof SHIPPING_QUERY_KEY_GEN.getAdminShippingList>,
    GET_ADMIN_SHIPPING_LIST_RES
  >({
    queryKey: SHIPPING_QUERY_KEY_GEN.getAdminShippingList(params),
    requestOptions: {
      method: "get",
      path: "/shipping/admin/new",
      apiType: "BofulDefault",
      params,
    },

    keepPreviousData: true,

    enabled,

    onSuccess,
  });

  return { ...queryResult };
}

function useGetPDAShippingList({ params }: { params: GET_SHIPPING_REQ }) {
  const setShippingList = useSetRecoilState(
    FULFILLMENT_SHIPPING_ATOMS.SHIPPING_LIST
  );

  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<typeof SHIPPING_QUERY_KEY_GEN.getPDAShippingList>,
    ShippingList
  >({
    queryKey: SHIPPING_QUERY_KEY_GEN.getPDAShippingList(params),
    requestOptions: {
      method: "get",
      path: `/shipping/worker/pda/pending`,
      apiType: "BofulDefault",
      params,
    },

    keepPreviousData: true,

    onSuccess: ({ list }) => setShippingList(list),

    failureModalInfo: {
      customizeMessage: () => ({
        title: `의뢰 리스트 조회 중에 오류가 발생했습니다.`,
      }),
    },
  });

  return { ...queryResult };
}

function useIssueShippingInvoice() {
  const queryClient = useQueryClient();

  const mutation = useAppMutation<{ ids: number[] }, ShippingListItem[]>({
    requestOptions: {
      method: "patch",
      path: "/shipping/worker/pda/force/print",
      apiType: "BofulDefault",
    },

    onSuccess: () =>
      queryClient.invalidateQueries(
        SHIPPING_QUERY_KEY_GEN.getPDAShippingList({})
      ),

    failureModalInfo: {
      customizeMessage: () => ({
        title: "송장을 출력하는 중에 중에 오류가 발생했습니다.",
      }),
    },
  });

  return { ...mutation };
}

function useMoveToMyTask(options?: {
  setUnSetRowInfo: Dispatch<SetStateAction<UnSetRowInfo | undefined>>;
}) {
  const queryClient = useQueryClient();

  const mutation = useAppMutation<{ ids: number[] }, { result: string }>({
    requestOptions: {
      method: "patch",
      path: "/shipping/worker/pda/task",
      apiType: "BofulDefault",
    },

    onSuccess: () =>
      queryClient.invalidateQueries(
        SHIPPING_QUERY_KEY_GEN.getPDAShippingList({})
      ),

    successModalInfo: {
      handleConfirmSuccess: (initQuery) => {
        initQuery();

        options?.setUnSetRowInfo?.(undefined);
      },
    },

    failureModalInfo: {
      customizeMessage: () => ({
        title:
          "미배정의뢰를 My의뢰로 이동시키는 중에 중에 오류가 발생했습니다.",
      }),
    },
  });

  return { ...mutation };
}

function useGetCompletedPickingList({
  params,
  enabled,
}: {
  params: CommonListRequest;
  enabled: boolean;
}) {
  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<typeof SHIPPING_QUERY_KEY_GEN.getCompletedPickingList>,
    CompletedPickingList
  >({
    queryKey: SHIPPING_QUERY_KEY_GEN.getCompletedPickingList(params),
    requestOptions: {
      method: "get",
      path: `/shipping/worker/pda/picking`,
      apiType: "BofulDefault",
      params,
    },

    enabled,

    keepPreviousData: true,

    failureModalInfo: {
      customizeMessage: () => ({
        title: `피킹 완료 리스트 조회 중에 오류가 발생했습니다.`,
      }),
    },
  });

  return { ...queryResult };
}

function useScanPickingInvoice({
  type,
  openCanceledPickingInvoice,
  onScanButtonSelect,
}: {
  openCanceledPickingInvoice: () => void;
  onScanButtonSelect: (type: ScanType | undefined) => void;
} & (
  | {
      type: "scan";
    }
  | {
      type: "directInput";
    }
)) {
  const setCanNotLeavePage = useSetRecoilState(
    FULFILLMENT_COMMON_ATOMS.CAN_NOT_LEAVE_PAGE
  );
  const setScannedPickingInvoice = useSetRecoilState(
    FULFILLMENT_SHIPPING_ATOMS.SCANNED_PICKING_INVOICE
  );
  const setIsNoNeedToPacking = useSetRecoilState(
    FULFILLMENT_SHIPPING_ATOMS.IS_NO_NEED_TO_PACKING
  );

  const mutation = useAppMutation<
    unknown,
    ShippingListItem,
    { invoiceNo: string }
  >({
    requestOptions: {
      method: "patch",
      path: ({ invoiceNo }) => `/shipping/worker/picking/${invoiceNo}`,
      apiType: "BofulDefault",
    },

    onSuccess: (scannedInvoiceData: ShippingListItem) => {
      // 본 운송 건은 취소되었습니다.
      if (scannedInvoiceData.shippingStatus === "cancel") {
        openCanceledPickingInvoice();
        return;
      }

      setCanNotLeavePage(true);

      onScanButtonSelect("location");

      setScannedPickingInvoice({
        shippingId: scannedInvoiceData.id,
        invoiceNo: scannedInvoiceData.invoiceNo,
        deliveryType: scannedInvoiceData.deliveryType,
        items: scannedInvoiceData.items ?? [],
        teamId: scannedInvoiceData.teamId,
        companyName: scannedInvoiceData.team?.company ?? "-",
        teamName: scannedInvoiceData.team?.name ?? "-",
      });

      setIsNoNeedToPacking(!scannedInvoiceData.isPacking);
    },

    failureModalInfo: {
      customizeMessage: (failureInfo): QueryResponseHandlerCustomMessage => {
        const isScan = type === "scan";

        const errorMessageAsDefault = {
          title: `송장(QR)을  ${
            isScan ? "스캔" : "입력"
          }하는 중에 오류가 발생했습니다.`,
        };

        const getErrorMessageAsCode400 = ({
          errorCode,
        }: {
          errorCode: string | undefined;
        }) =>
          ({
            E4000: {
              messageType: "titleAndBody" as const,
              title: `올바른 송장(QR)을 ${isScan ? "스캔" : "입력"}해주세요.`,
              body: getErrorMessageAsE4000({
                failureInfo,
              }),
            },
            E4002: {
              messageType: "titleOnly" as const,
              title: "본 운송 건은 취소되었습니다.",
              className: "title-warning-modal",
            },
            E4008: {
              messageType: "titleOnly" as const,
              title: "다른 담당자에 의해 피킹중인 송장(QR)입니다.",
            },
            E4009: {
              messageType: "titleAndBody" as const,
              title: `올바른 송장(QR)을 ${isScan ? "스캔" : "입력"}해주세요.`,
              body: getErrorMessageAsE4009({
                failureInfo,
              }),
            },
            E4021: {
              messageType: "titleAndBody" as const,
              title: `올바른 송장(QR)을 ${isScan ? "스캔" : "입력"}해주세요.`,
              body: `이미 피킹이 완료된 송장(QR)입니다.`,
            },
          }[errorCode ?? ""] ?? errorMessageAsDefault);

        const getErrorMessage = ({
          code,
          errorCode,
        }: {
          code: number | undefined;
          errorCode: string | undefined;
        }) =>
          ({
            400: getErrorMessageAsCode400({ errorCode }),
            404: {
              messageType: "titleAndBody" as const,
              title: `올바른 송장(QR)을 ${isScan ? "스캔" : "입력"}해주세요.`,
              body: "해당 출고 건이 없습니다. ",
            },
          }[code ?? 0] ?? errorMessageAsDefault);

        return getErrorMessage({
          code: failureInfo?.code,
          errorCode: failureInfo?.errorCode,
        });
      },
    },
  });

  return { ...mutation };
}

function useStartPickingScanning() {
  const { shippingId } = useRecoilValue(
    FULFILLMENT_SHIPPING_ATOMS.SCANNED_PICKING_INVOICE
  );

  const mutation = useAppMutation<unknown, ShippingListItem>({
    requestOptions: {
      method: "patch",
      path: `/shipping/worker/picking/start/scanning/${shippingId}`,
      apiType: "BofulDefault",
    },

    failureModalInfo: {
      customizeMessage: () => ({
        title: "피킹 스캔을 시작하는 중에 중에 오류가 발생했습니다.",
      }),
    },
  });

  return { ...mutation };
}

function useChangePickingLocation() {
  const mutation = useAppMutation<
    CHANGE_PICKING_LOCATION_REQ,
    { result: string }
  >({
    requestOptions: {
      method: "patch",
      path: `/shipping/worker/picking/location`,
      apiType: "BofulDefault",
    },

    failureModalInfo: {
      customizeMessage: () => ({
        title: "피킹 위치 변경 중에 오류가 발생했습니다.",
      }),
    },
  });

  return { ...mutation };
}

function useCompletePicking({
  resetPickingState,
}: {
  resetPickingState: () => void;
}) {
  const { invoiceNo, shippingId } = useRecoilValue(
    FULFILLMENT_SHIPPING_ATOMS.SCANNED_PICKING_INVOICE
  );
  const isNoNeedToPacking = useRecoilValue(
    FULFILLMENT_SHIPPING_ATOMS.IS_NO_NEED_TO_PACKING
  );
  const resetIsNoNeedToPacking = useResetRecoilState(
    FULFILLMENT_SHIPPING_ATOMS.IS_NO_NEED_TO_PACKING
  );
  const resetScannedPickingInvoice = useResetRecoilState(
    FULFILLMENT_SHIPPING_ATOMS.SCANNED_PICKING_INVOICE
  );
  const setCanNotLeavePage = useSetRecoilState(
    FULFILLMENT_COMMON_ATOMS.CAN_NOT_LEAVE_PAGE
  );

  const queryClient = useQueryClient();

  const {
    mutate: startPacking,
    ResponseHandler: ResponseHandlerOfStartingPacking,
    ResponseHandlerOfCompletingPacking,
  } = SHIPPING_QUERY.useStarPacking({
    resetPickingState,
  });

  const mutation = useAppMutation<unknown, ShippingListItem>({
    requestOptions: {
      method: "patch",
      path: `/shipping/worker/picking/complete/${shippingId}`,
      apiType: "BofulDefault",
    },

    onSuccess: () => {
      if (isNoNeedToPacking) {
        startPacking();

        resetIsNoNeedToPacking();
      }
    },

    successModalInfo:
      isNoNeedToPacking === false
        ? {
            handleConfirmSuccess: (initQuery) => {
              initQuery();

              resetScannedPickingInvoice();
              setCanNotLeavePage(false);
              queryClient.invalidateQueries(
                SHIPPING_QUERY_KEY_GEN.completedPickingList()
              );

              resetPickingState();
            },
            customizeMessage: () => ({
              messageType: "titleAndBody",
              title: `${invoiceNo} (송장(QR)번호)`,
              body: `피킹이 완료되었습니다.`,
            }),
          }
        : undefined,
    failureModalInfo: {
      customizeMessage: () => ({
        title: "피킹완료 중에 오류가 발생했습니다.",
      }),
    },
  });

  return {
    ...mutation,
    ResponseHandlerOfStartingPacking,
    ResponseHandlerOfCompletingPacking,
  };
}

function useReportShippingProblem({
  wmsStatus,
  handleReportProblemModalClose,
  resetPickingState,
}: {
  wmsStatus: "picking" | "packing";
  handleReportProblemModalClose: () => void;
  resetPickingState: () => void;
}) {
  const resetScannedPickingInvoice = useResetRecoilState(
    FULFILLMENT_SHIPPING_ATOMS.SCANNED_PICKING_INVOICE
  );
  const setCanNotLeavePage = useSetRecoilState(
    FULFILLMENT_COMMON_ATOMS.CAN_NOT_LEAVE_PAGE
  );

  const queryClient = useQueryClient();

  const isPicking = wmsStatus === "picking";

  const mutation = useAppMutation<REPORT_PROBLEM_REQ, REPORT_PROBLEM_RES>({
    requestOptions: {
      method: "post",
      path: `/report`,
      apiType: "BofulDefault",
    },

    successModalInfo: isPicking
      ? {
          handleConfirmSuccess: (initQuery) => {
            initQuery();

            resetScannedPickingInvoice();
            setCanNotLeavePage(false);
            queryClient.invalidateQueries(
              SHIPPING_QUERY_KEY_GEN.completedPickingList()
            );

            handleReportProblemModalClose();
            resetPickingState();
          },
          customizeMessage: () => ({
            messageType: "titleAndBody",
            title: "피킹이 보류되었습니다.",
            body: "출고한 위치에 재입고 시켜주세요.",
          }),
        }
      : undefined,

    failureModalInfo: {
      customizeMessage: () => ({
        title: "문제보고 중에 중에 오류가 발생했습니다.",
      }),
      barcodeValue: isPicking ? undefined : "H_REP_PRO",
    },
  });

  return { ...mutation };
}

function useGetShipmentSummary<DeliveryType extends ShippingDeliveryType>({
  type,
  params,
}: {
  type: DeliveryType;
  params: DeliveryType extends "parcel"
    ? { parcelCompany: ParcelCompanyType }
    : { truckCompany: TruckCompanyType };
}) {
  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<typeof SHIPPING_QUERY_KEY_GEN.getShipmentSummary>,
    DeliveryType extends "parcel" ? ShipmentParcelSummary : ShipmentSummary
  >({
    queryKey: SHIPPING_QUERY_KEY_GEN.getShipmentSummary(params),
    requestOptions: {
      method: "get",
      path: `/shipping/shipment/${type}/summary`,
      apiType: "BofulDefault",
      params,
    },

    failureModalInfo: {
      customizeMessage: () => ({
        title: `출하 정보 조회 중에 오류가 발생했습니다.`,
      }),
    },
  });

  return { ...queryResult };
}

function useGetShipmentToday<DeliveryType extends ShippingDeliveryType>({
  type,
  status,
  params,
}: {
  type: DeliveryType;
  status: ShipmentStatusType;
  params: DeliveryType extends "parcel"
    ? GET_SHIPMENT_PARCEL_TODAY_REQ
    : GET_SHIPMENT_TRUCK_TODAY_REQ;
}) {
  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<typeof SHIPPING_QUERY_KEY_GEN.getShipmentToday>,
    ShippingShipmentList
  >({
    queryKey: SHIPPING_QUERY_KEY_GEN.getShipmentToday(params),
    requestOptions: {
      method: "get",
      path: `/shipping/shipment/${type}/today`,
      apiType: "BofulDefault",
      params,
    },

    keepPreviousData: true,

    enabled: status === "expected",

    failureModalInfo: {
      customizeMessage: () => ({
        title: `당일 출하 예정 리스트 조회 중에 오류가 발생했습니다.`,
      }),
    },
  });

  return { ...queryResult };
}

function useGetShipmentDone<DeliveryType extends ShippingDeliveryType>({
  type,
  status,
  params,
}: {
  type: DeliveryType;
  status: ShipmentStatusType;
  params: DeliveryType extends "parcel"
    ? GET_SHIPMENT_PARCEL_DONE_REQ
    : GET_SHIPMENT_TRUCK_DONE_REQ;
}) {
  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<typeof SHIPPING_QUERY_KEY_GEN.getShipmentDone>,
    ShippingShipmentList
  >({
    queryKey: SHIPPING_QUERY_KEY_GEN.getShipmentDone(params),
    requestOptions: {
      method: "get",
      path: `/shipping/shipment/${type}/done`,
      apiType: "BofulDefault",
      params,
    },

    keepPreviousData: true,

    enabled: status === "completed",

    failureModalInfo: {
      customizeMessage: () => ({
        title: `현재 마감 리스트 조회 중에 오류가 발생했습니다.`,
      }),
    },
  });

  return { ...queryResult };
}

function useGetShipmentDelay<DeliveryType extends ShippingDeliveryType>({
  type,
  status,
  params,
}: {
  type: DeliveryType;
  status: ShipmentStatusType;
  params: DeliveryType extends "parcel"
    ? GET_SHIPMENT_PARCEL_DELAY_REQ
    : GET_SHIPMENT_TRUCK_DELAY_REQ;
}) {
  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<typeof SHIPPING_QUERY_KEY_GEN.getShipmentDelay>,
    ShippingShipmentList
  >({
    queryKey: SHIPPING_QUERY_KEY_GEN.getShipmentDelay(params),
    requestOptions: {
      method: "get",
      path: `/shipping/shipment/${type}/delay`,
      apiType: "BofulDefault",
      params,
    },

    keepPreviousData: true,

    enabled: status === "unprocessed",

    failureModalInfo: {
      customizeMessage: () => ({
        title: `현재 미처리 리스트 조회 중에 오류가 발생했습니다.`,
      }),
    },
  });

  return { ...queryResult };
}

function useGetShipmentParcelOrder({
  status,
  params,
}: {
  status: ShipmentStatusType;
  params: GET_SHIPMENT_PARCEL_ORDER_REQ;
}) {
  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<typeof SHIPPING_QUERY_KEY_GEN.getShipmentParcelOrder>,
    ShippingShipmentList
  >({
    queryKey: SHIPPING_QUERY_KEY_GEN.getShipmentParcelOrder(params),
    requestOptions: {
      method: "get",
      path: "/shipping/shipment/parcel/order",
      apiType: "BofulDefault",
      params,
    },

    keepPreviousData: true,

    enabled: status === "ready" || status === "done",

    failureModalInfo: {
      customizeMessage: () => ({
        title:
          status === "ready"
            ? `출하 준비완료 리스트 조회 중에 오류가 발생했습니다.`
            : `출하 마감 리스트 조회 중에 오류가 발생했습니다.`,
      }),
    },
  });

  return { ...queryResult };
}

function useGetShipmentTruckPackings({ shippingId }: { shippingId: number }) {
  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<typeof SHIPPING_QUERY_KEY_GEN.getShipmentTruckPackings>,
    ShippingShipmentTruckPackings
  >({
    queryKey: SHIPPING_QUERY_KEY_GEN.getShipmentTruckPackings({ shippingId }),
    requestOptions: {
      method: "get",
      path: `/shipping/shipment/truck/packings/${shippingId}`,
      apiType: "BofulDefault",
    },

    enabled: shippingId !== 0,

    failureModalInfo: {
      customizeMessage: () => ({
        title: `스캔 리스트 조회 중에 오류가 발생했습니다.`,
      }),
    },
  });

  return { ...queryResult };
}

function useCompleteShipmentTruck({
  shippingId,
  handleConsigneeSignatureModalClose,
  status,
}: {
  shippingId: number;
  handleConsigneeSignatureModalClose: () => void;
  status: ShipmentStatusType;
}) {
  const queryClient = useQueryClient();

  const mutation = useAppMutation<unknown, { result: string }>({
    requestOptions: {
      method: "patch",
      path: `/shipping/shipment/truck/signature/${shippingId}`,
      apiType: "BofulDefault",
    },

    onSuccess: () => {
      handleConsigneeSignatureModalClose();

      status === "expected"
        ? queryClient.invalidateQueries(SHIPPING_QUERY_KEY_GEN.shipmentToday())
        : queryClient.invalidateQueries(SHIPPING_QUERY_KEY_GEN.shipmentDelay());
    },

    failureModalInfo: {
      customizeMessage: () => ({
        title: `인수자 서명 중에 오류가 발생했습니다.`,
      }),
    },
  });

  return { ...mutation };
}

/**
 * 송장번호 채번.
 * 응답으로 송장번호는 것은 아님 (DB상 송장번호가 업데이트 되는 것)
 */
function useInvoiceNumbering(
  sideEffectOptions?: MutationSideEffectType<
    INVOICE_NUMBERING_REQ,
    INVOICE_NUMBERING_RES
  >
) {
  const mutation = useAppMutation<INVOICE_NUMBERING_REQ, INVOICE_NUMBERING_RES>(
    {
      requestOptions: {
        method: "post",
        path: `/parcel/invoice`,
        apiType: "BofulDefault",
      },
      ...sideEffectOptions,
    }
  );

  return { ...mutation };
}

function useInvoiceNumberingByParcelCompany(
  sideEffectOptions?: MutationSideEffectType<
    INVOICE_NUMBERING_REQ,
    INVOICE_NUMBERING_RES
  >
) {
  const mutation = useAppMutation<
    INVOICE_NUMBERING_BY_PARCEL_COMPANY_REQ,
    INVOICE_NUMBERING_RES
  >({
    requestOptions: {
      method: "post",
      path: `/parcel/invoice`,
      apiType: "BofulDefault",
    },
    ...sideEffectOptions,
  });

  return { ...mutation };
}

function useGetInvoicePrintingData({
  hidesLoading,
  ...sideEffectOptions
}: MutationSideEffectType<
  GET_INVOICE_PRINTING_DATA_REQ,
  GET_INVOICE_PRINTING_DATA_RES
> & {
  hidesLoading?: boolean;
}) {
  const mutation = useAppMutation<
    GET_INVOICE_PRINTING_DATA_REQ,
    GET_INVOICE_PRINTING_DATA_RES
  >({
    requestOptions: {
      method: "patch",
      path: "parcel/print",
      apiType: "BofulDefault",
    },

    hidesLoading,

    ...sideEffectOptions,
  });

  return { ...mutation };
}

/**
 * 거래명세서를 출력할때 수반되는 status update를 요청
 */
function useUpdateStatusWhenPrintingTransactionStatement() {
  const mutation = useAppMutation<
    UPDATE_STATUS_WHEN_PRINTING_TRANSACTION_STATEMENT_REQ,
    UPDATE_STATUS_WHEN_PRINTING_TRANSACTION_STATEMENT_RES
  >({
    requestOptions: {
      method: "post",
      path: `/shipping/admin/new/print/statement`,
      apiType: "BofulDefault",
    },
  });

  return { ...mutation };
}

/**
 * 출하 완료 처리
 *
 * 운송수단이 '택배'일 경우에만 '출하완료'로 변경하고,
 * '트럭' 또는 '직접수령'일 경우 '배송완료'로 변경됨에 유의
 */
function useCompleteShipment(
  props?: {
    successModalInfo: QueryResponseHandlerSuccessModalInfo<COMPLETE_SHIPMENT_RES>;
  } & MutationSideEffectType<COMPLETE_SHIPMENT_REQ, COMPLETE_SHIPMENT_RES>
) {
  const { successModalInfo, ...sideEffectOptions } = props || {};

  const mutation = useAppMutation<COMPLETE_SHIPMENT_REQ, COMPLETE_SHIPMENT_RES>(
    {
      requestOptions: {
        method: "patch",
        path: `/shipping/admin/new/shipment/complete`,
        apiType: "BofulDefault",
      },
      successModalInfo,
      ...sideEffectOptions,
    }
  );

  return { ...mutation };
}

function useUpdateParcelDeliveryDetail({ shippingId }: { shippingId: number }) {
  const mutation = useAppMutation<
    UPDATE_PARCEL_DELIVERY_DETAIL_REQ,
    ShippingItemDetail
  >({
    requestOptions: {
      method: "put",
      path: `/shipping/admin/new/parcel/${shippingId}`,
      apiType: "BofulDefault",
    },
  });

  return { ...mutation };
}

function useGetTruckDeliveryDetail({
  shippingId,
  enabled,
  handleConfirmFailure,
}: {
  shippingId: number;
  enabled: boolean;
  handleConfirmFailure?: () => void;
}) {
  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<typeof SHIPPING_QUERY_KEY_GEN.getTruckDeliveryDetail>,
    GET_TRUCK_DELIVERY_DETAIL_RES
  >({
    queryKey: SHIPPING_QUERY_KEY_GEN.getTruckDeliveryDetail({ shippingId }),
    requestOptions: {
      method: "get",
      path: `/shipping/admin/new/trucking/${shippingId}`,
    },

    enabled,

    failureModalInfo: { handleConfirmFailure },
  });

  return { ...queryResult };
}

function useUpdateTruckDeliveryDetail({ shippingId }: { shippingId: number }) {
  const mutation = useAppMutation<
    UPDATE_TRUCK_DELIVERY_DETAIL_REQ,
    ShippingItemDetail
  >({
    requestOptions: {
      method: "put",
      path: `/shipping/admin/new/trucking/${shippingId}`,
      apiType: "BofulDefault",
    },
  });

  return { ...mutation };
}

function useValidateShippingExcelInvoiceList() {
  const mutation = useAppMutation<
    { fileKey: string },
    VALIDATE_SHIPPING_EXCEL_INVOICE_LIST_RES
  >({
    requestOptions: {
      method: "post",
      path: `/shipping/excel/invoices/validate`,
      apiType: "BofulDefault",
    },
  });

  const successExcelList = useMemo(
    () => mutation.data?.filter(({ hasError }) => !hasError),
    [mutation.data]
  );

  const failureExcelList = useMemo(
    () => mutation.data?.filter(({ hasError }) => hasError),
    [mutation.data]
  );

  return { ...mutation, successExcelList, failureExcelList };
}

function useSaveShippingExcelInvoiceList() {
  const mutation = useAppMutation<
    { data: ShippingInvoiceExcelItem[] },
    ShippingItemDetail
  >({
    requestOptions: {
      method: "patch",
      path: `/shipping/excel/invoices/save`,
      apiType: "BofulDefault",
    },
  });

  return { ...mutation };
}

function useGetShippingDetail({
  id,
  enabled,
  onSuccess,
}: GET_SHIPPING_DETAIL_REQ & {
  enabled?: boolean;
  onSuccess?: (data: GET_SHIPPING_DETAIL_RES) => void;
}) {
  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<typeof SHIPPING_QUERY_KEY_GEN.getShippingDetail>,
    GET_SHIPPING_DETAIL_RES
  >({
    queryKey: SHIPPING_QUERY_KEY_GEN.getShippingDetail({ id }),
    requestOptions: {
      method: "get",
      path: `/shipping/detail`,
      apiType: "BofulDefault",
      params: {
        id,
      },
    },
    enabled,
    onSuccess,
  });

  return { ...queryResult };
}

function useChangeShippingItemsLocations({
  onSuccess,
}: {
  onSuccess?: () => void;
}) {
  const mutation = useAppMutation<
    CHANGE_SHIPPING_ITEMS_LOCATIONS_REQ,
    CHANGE_SHIPPING_ITEMS_LOCATIONS_RES
  >({
    requestOptions: {
      method: "patch",
      path: `/shipping/admin/new/item/locations`,
      apiType: "BofulDefault",
    },

    onSuccess,

    failureModalInfo: {
      customizeMessage: () => ({
        title: "출고 아이템 로케이션 변경에서 오류가 발생했습니다.",
      }),
    },
  });

  return { ...mutation };
}

function useForceShipping({ onSuccess }: { onSuccess?: () => void }) {
  const mutation = useAppMutation<FORCE_SHIPPING_REQ, FORCE_SHIPPING_RES>({
    requestOptions: {
      method: "post",
      path: `/shipping/admin/new/force`,
      apiType: "BofulDefault",
    },

    onSuccess,

    failureModalInfo: {
      customizeMessage: (failureInfo) => {
        const errorMessage: {
          [errorCode: string]: QueryResponseHandlerCustomMessage;
        } = {
          E4031: {
            messageType: "titleOnly",
            title:
              "강제 피킹패킹 처리 할 수 있는 출고가 아닙니다. 출고 단계가 적절치 않음",
          },
          E4000: {
            messageType: "titleOnly",
            title: "작업 중인 출고가 아닙니다.",
          },
          E4002: {
            messageType: "titleOnly",
            title: "취소처리된 출고입니다.",
          },
          E4008: {
            messageType: "titleOnly",
            title: "작업자가 다릅니다.",
          },
          E4009: {
            messageType: "titleOnly",
            title: "출고 단계가 적절치 않습니다.",
          },
          E4020: {
            messageType: "titleOnly",
            title: "피킹이 완료되지 않았습니다.",
          },
          E4021: {
            messageType: "titleOnly",
            title: "피킹단계를 이미 마친 출고입니다.",
          },
          E4024: {
            messageType: "titleOnly",
            title: "이미 출고된 건입니다.",
          },
          E4025: {
            messageType: "titleOnly",
            title: "이미 반품된 건입니다.",
          },
        };

        return (
          errorMessage[failureInfo?.errorCode ?? ""] ?? {
            title: "강제 출고 중에 오류가 발생했습니다.",
          }
        );
      },
    },
  });

  return { ...mutation };
}

function useAddInvoice({
  successModalInfo,
}: {
  successModalInfo?: QueryResponseHandlerSuccessModalInfo<ADD_INVOICE_RES>;
}) {
  const mutation = useAppMutation<ADD_INVOICE_REQ, ADD_INVOICE_RES>({
    requestOptions: {
      method: "post",
      path: `/parcel/add/invoice`,
      apiType: "BofulDefault",
    },

    successModalInfo,

    failureModalInfo: {
      customizeMessage: (failureInfo) => {
        if (failureInfo?.errorCode === "E4005") {
          return {
            title: "존재하지 않는 출고 요청 번호입니다.",
          };
        }

        if (failureInfo?.errorCode === "E4043") {
          return {
            title: "송장 채번이 필요합니다.",
          };
        }

        return {
          title: "추가 송장을 생성하는 중에 오류가 발생했습니다.",
        };
      },
    },
  });

  return { ...mutation };
}

const useCombinePacking = () => {
  const mutation = useAppMutation<
    unknown,
    { result: string },
    { shippingId: number }
  >({
    requestOptions: {
      method: "patch",
      path: ({ shippingId }) => `/shipping/admin/new/combined/${shippingId}`,
      apiType: "BofulDefault",
    },

    failureModalInfo: {
      customizeMessage: (failureInfo) => {
        const errorMessage: {
          [errorCode: string]: QueryResponseHandlerCustomMessage;
        } = {
          E4048: {
            messageType: "titleOnly",
            title: "작업상태가 일치하지 않는 출고 건입니다.",
          },
          E4005: {
            messageType: "titleOnly",
            title: "존재하지 않는 출고 건입니다.",
          },
          E4047: {
            messageType: "titleOnly",
            title: "패킹불필요 출고 건이 아닙니다.",
          },
        };

        return (
          errorMessage[failureInfo?.errorCode ?? ""] ?? {
            title: "합포장 중에 중에 오류가 발생했습니다.",
          }
        );
      },
    },
  });

  return { ...mutation };
};

function useGetShippingListBySkuId({
  skuId,
  enabled,
  onError,
}: GET_SHIPPING_LIST_BY_SKU_ID_REQ_PATH_PARAMS & {
  enabled?: boolean;
  onError?: (
    error: ResponseFailureInfo | undefined,
    hideFailureModal: () => void
  ) => void;
}) {
  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<typeof SHIPPING_QUERY_KEY_GEN.getShippingListBySkuId>,
    GET_SHIPPING_LIST_BY_SKU_ID_RES
  >({
    queryKey: SHIPPING_QUERY_KEY_GEN.getShippingListBySkuId({ skuId }),
    requestOptions: {
      method: "get",
      path: `/shipping/admin/new/sku/${skuId}`,
      apiType: "BofulDefault",
    },

    enabled,

    onError,

    failureModalInfo: {
      customizeMessage: () => ({
        title: "출고 내역 조회 중에 오류가 발생했습니다.",
      }),
    },
  });

  return { ...queryResult };
}

/**
 * 화물차량의 상태를 [피킹 -> 의뢰대기] 상태로 되돌림
 */
const useResetShippingStatus = () => {
  const mutation = useAppMutation<
    unknown,
    { result: string },
    { shippingId: number }
  >({
    requestOptions: {
      method: "put",
      path: ({ shippingId }) => `/shipping/admin/new/reset/${shippingId}`,
      apiType: "BofulDefault",
    },

    failureModalInfo: {
      customizeMessage: (failureInfo) => {
        const errorMessage: {
          [errorCode: string]: QueryResponseHandlerCustomMessage;
        } = {
          E4100: {
            messageType: "titleOnly",
            title: "일치하는 출고 정보가 존재하지 않습니다.",
          },
        };

        return (
          errorMessage[failureInfo?.errorCode ?? ""] ?? {
            title: "출고 상태 되돌리기 중에 오류가 발생했습니다.",
          }
        );
      },
    },
  });

  return { ...mutation };
};

function useAddShippingAdminMemo() {
  const mutation = useAppMutation<
    ADD_SHIPPING_ADMIN_MEMO_REQ,
    { result: string },
    ADD_SHIPPING_ADMIN_MEMO_REQ_PATH_PARAMS
  >({
    requestOptions: {
      method: "post",
      path: ({ shippingId }) => `shipping/admin/new/memo/${shippingId}`,
      apiType: "BofulDefault",
    },

    failureModalInfo: {
      customizeMessage: () => ({
        title: "메모 등록 중에 오류가 발생했습니다.",
      }),
    },
  });

  return { ...mutation };
}

function useEditDeliveryInfo() {
  const mutation = useAppMutation<EDIT_DELIVERY_INFO_REQ, RESULT_RES>({
    requestOptions: {
      method: "patch",
      path: `shipping/admin/new/delivery`,
      apiType: "BofulDefault",
    },

    failureModalInfo: {
      customizeMessage: (failureInfo) => {
        const errorMessage: {
          [errorCode: string]: QueryResponseHandlerCustomMessage;
        } = {
          E4301: {
            messageType: "titleOnly",
            title: "요청한 일자에는 배송이 진행될 수 없습니다.",
          },
          E4304: {
            messageType: "titleOnly",
            title: "운송 수단 변경은 의뢰 대기 상태에서만 변경이 가능합니다.",
          },
        };

        return (
          errorMessage[failureInfo?.errorCode ?? ""] ?? {
            title: "배송 정보 수정 중에 오류가 발생했습니다.",
          }
        );
      },
    },
  });

  return { ...mutation };
}

function useGetShippingSearchSuggestionList({
  enabled,
  ...params
}: {
  enabled?: boolean;
} & GET_SHIPPING_SEARCH_SUGGESTION_LIST_REQ) {
  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<typeof SHIPPING_QUERY_KEY_GEN.getShippingSearchSuggestionList>,
    GET_SHIPPING_SEARCH_SUGGESTION_LIST_RES
  >({
    queryKey: SHIPPING_QUERY_KEY_GEN.getShippingSearchSuggestionList(params),
    requestOptions: {
      method: "get",
      path: "/search/suggestion/shipping",
      apiType: "BofulDefault",
      params,
    },

    enabled,
  });

  return { ...queryResult };
}

function useValidateDomesticShippingExcel() {
  const mutation = useAppMutation<
    VALIDATE_DOMESTIC_SHIPPING_EXCEL_REQ,
    VALIDATE_DOMESTIC_SHIPPING_EXCEL_RES
  >({
    requestOptions: {
      method: "post",
      path: "/shipping/excel/validate",
      apiType: "BofulDefault",
    },
  });

  return mutation;
}

function useUploadDomesticShippingExcel({
  successModalInfo,
  failureModalInfo,
}: {
  successModalInfo?: QueryResponseHandlerSuccessModalInfo<UPLOAD_DOMESTIC_SHIPPING_EXCEL_RES>;
  failureModalInfo?: QueryResponseHandlerFailureModalInfo;
}) {
  const mutation = useAppMutation<
    UPLOAD_DOMESTIC_SHIPPING_EXCEL_REQ,
    UPLOAD_DOMESTIC_SHIPPING_EXCEL_RES
  >({
    requestOptions: {
      method: "post",
      path: "/shipping/excel/save",
      apiType: "BofulDefault",
    },

    successModalInfo,
    failureModalInfo,
  });

  return mutation;
}

function useValidateOverseasShippingExcel() {
  const mutation = useAppMutation<
    VALIDATE_OVERSEAS_SHIPPING_EXCEL_REQ,
    VALIDATE_OVERSEAS_SHIPPING_EXCEL_RES
  >({
    requestOptions: {
      method: "post",
      path: "/shipping/excel/validate/overseas",
      apiType: "BofulDefault",
    },
  });

  return mutation;
}

function useUploadOverseasShippingExcel({
  successModalInfo,
  failureModalInfo,
}: {
  successModalInfo?: QueryResponseHandlerSuccessModalInfo<UPLOAD_OVERSEAS_SHIPPING_EXCEL_RES>;
  failureModalInfo?: QueryResponseHandlerFailureModalInfo;
}) {
  const mutation = useAppMutation<
    UPLOAD_OVERSEAS_SHIPPING_EXCEL_REQ,
    UPLOAD_OVERSEAS_SHIPPING_EXCEL_RES
  >({
    requestOptions: {
      method: "post",
      path: "/shipping/excel/save/overseas",
      apiType: "BofulDefault",
    },

    successModalInfo,
    failureModalInfo,
  });

  return mutation;
}

function useReorderShipping() {
  const mutation = useAppMutation<REORDER_SHIPPING_REQ, REORDER_SHIPPING_RES>({
    requestOptions: {
      method: "post",
      path: `/shipper/reorder`,
      apiType: "BofulDefault",
    },

    failureModalInfo: {
      customizeMessage: (failureInfo) => {
        const errorMessage: {
          [errorCode: string]: QueryResponseHandlerCustomMessage;
        } = {
          E4128: { title: "기존 출고건에 포함되지 않았던 물품 정보입니다." },
          E4129: {
            title: "한번 재출고가 진행된 출고건은 재출고가 불가능합니다.",
          },
          E4301: { title: "요청한 일자에는 배송이 진행될 수 없습니다." },
          E4302: { title: "비정상적인 출고 요청입니다." },
          E4300: { title: "존재하지 않는 센터 ID 입니다." },
          E5105: { title: "현재 재고가 요청한 재고보다 적습니다." },
        };

        return (
          errorMessage[failureInfo?.errorCode ?? ""] ?? {
            title: "출고 등록 중에 오류가 발생했습니다.",
          }
        );
      },
    },
  });

  return mutation;
}

/**
 * 택배사 연동이 되지 않은 경우 택배 정보 입력
 */
function useAddParcelDetail() {
  const mutation = useAppMutation<
    ADD_PARCEL_INFO_REQ,
    ShippingItemDetail,
    ADD_PARCEL_INFO_REQ_PATH_PARAMS
  >({
    requestOptions: {
      method: "put",
      path: ({ shippingId }) =>
        `/shipping/admin/new/parcel/pending/${shippingId}`,
      apiType: "BofulDefault",
    },

    failureModalInfo: {
      customizeMessage: () => ({
        title: "배송정보 수기 입력 중에 오류가 발생했습니다.",
      }),
    },
  });

  return mutation;
}

function useGetKyoungdongShippingFreightList({
  ids,
  enabled,
}: {
  ids: number[];
  enabled?: boolean;
}) {
  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<typeof SHIPPING_QUERY_KEY_GEN.getKyoungdongShippingFreightList>,
    GET_KYOUNGDONG_SHIPPING_FREIGHT_LIST_RES
  >({
    queryKey: SHIPPING_QUERY_KEY_GEN.getKyoungdongShippingFreightList({ ids }),
    requestOptions: {
      method: "get",
      path: `/shipping/admin/new/excel/freight`,
      apiType: "BofulDefault",
      params: {
        ids,
      },
    },
    enabled,
  });

  return { ...queryResult };
}

function useValidateKyoungDongShippingExcelInvoiceList() {
  const mutation = useAppMutation<
    { fileKey: string },
    VALIDATE_KYOUNGDONG_SHIPPING_EXCEL_INVOICE_LIST_RES
  >({
    requestOptions: {
      method: "post",
      path: `/shipping/excel/invoices/validate/freight`,
      apiType: "BofulDefault",
    },
  });

  const successExcelList = useMemo(
    () => mutation.data?.filter(({ hasError }) => !hasError),
    [mutation.data]
  );

  const failureExcelList = useMemo(
    () => mutation.data?.filter(({ hasError }) => hasError),
    [mutation.data]
  );

  return { ...mutation, successExcelList, failureExcelList };
}

function useSaveKyoungDongShippingExcelInvoiceList() {
  const mutation = useAppMutation<
    { data: KyoungDongShippingInvoiceExcelItem[] },
    ShippingItemDetail
  >({
    requestOptions: {
      method: "patch",
      path: `/shipping/excel/invoices/save/freight`,
      apiType: "BofulDefault",
    },
  });

  return { ...mutation };
}

function useCreateForcePicking() {
  const mutation = useAppMutation<
    unknown,
    CREATE_FORCE_PICKING_RES,
    CREATE_FORCE_PICKING_REQ_PATH_PARAMS
  >({
    requestOptions: {
      method: "patch",
      path: (pathParams) =>
        `/shipping/admin/new/force/picking/${pathParams.id}`,
      apiType: "BofulDefault",
    },
    failureModalInfo: {
      customizeMessage: () => ({
        title: "강제 피킹 처리 중에 오류가 발생했습니다.",
        body: "출고요청번호가 올바르지 않거나, 서버에서 오류가 발생했을 수 있습니다. 다시 시도해보시거나, 개발팀에 문의해주세요.",
      }),
    },
  });

  return { ...mutation };
}

function useEditCustomerDetail({
  shippingId,
}: {
  shippingId: number | undefined;
}) {
  const mutation = useAppMutation<
    EDIT_CUSTOMER_DETAIL_REQ,
    EDIT_CUSTOMER_DETAIL_RES
  >({
    requestOptions: {
      method: "patch",
      path: `/shipping/admin/new/address/${shippingId}`,
      apiType: "BofulDefault",
    },
  });

  return mutation;
}

function useCancelShippingForAdmin({
  onError,
}: {
  onError: (
    err: ResponseFailureInfo | undefined,
    hideFailureModal: () => void
  ) => void;
}) {
  const mutation = useAppMutation<
    CANCEL_SHIPPING_FOR_ADMIN_REQ,
    CANCEL_SHIPPING_FOR_ADMIN_RES
  >({
    requestOptions: {
      method: "post",
      path: `/canceling/master`,
      apiType: "BofulDefault",
    },

    onError,
  });

  return mutation;
}

function useCheckExistingShipping({
  params,
  enabled,
}: {
  params: CHECK_EXISTING_SHIPPING_REQ;
  enabled?: boolean;
}) {
  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<typeof SHIPPING_QUERY_KEY_GEN.checkExistingShipping>,
    CHECK_EXISTING_SHIPPING_RES
  >({
    queryKey: SHIPPING_QUERY_KEY_GEN.checkExistingShipping(params),
    requestOptions: {
      method: "get",
      path: `/shipper/exist`,
      apiType: "BofulDefault",
      params,
    },

    enabled,
  });

  return queryResult;
}

function useUpdateShipperMemo() {
  const mutation = useAppMutation<
    UPDATE_SHIPPER_MEMO_REQ,
    UPDATE_SHIPPER_MEMO_RES,
    UPDATE_SHIPPER_MEMO_REQ_PATH_PARAMS
  >({
    requestOptions: {
      method: "patch",
      path: ({ shippingId }) => `/shipper/${shippingId}/memo`,
      apiType: "BofulDefault",
    },

    failureModalInfo: {
      customizeMessage: () => ({
        title: "메모 수정 중에 오류가 발생했습니다.",
      }),
    },
  });

  return mutation;
}

function useDownloadShippingExcelList(
  params: DOWNLOAD_SHIPPING_EXCEL_LIST_REQ
) {
  const mutation = useAppMutation<void, BlobPart>({
    requestOptions: {
      method: "post",
      path: `/shipping/excel/download`,
      apiType: "BofulDefault",
      params,
      responseType: "blob",
    },

    failureModalInfo: {
      customizeMessage: () => ({
        title: "출고 목록 다운로드 중에 오류가 발생했습니다.",
      }),
    },
  });

  return mutation;
}

function useGetShippingSummary({
  enabled,
  shippingId,
  onError,
}: {
  enabled: boolean;
  shippingId: number;
  onError?: (
    error: ResponseFailureInfo | undefined,
    hideFailureModal: () => void
  ) => void;
}) {
  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<typeof SHIPPING_QUERY_KEY_GEN.getShippingSummary>,
    GET_SHIPPING_SUMMARY_SUMMARY_RES
  >({
    queryKey: SHIPPING_QUERY_KEY_GEN.getShippingSummary({
      shippingId,
    }),
    requestOptions: {
      method: "get",
      path: `/shipper/summary/${shippingId}`,
      apiType: "BofulDefault",
    },

    enabled,

    onError,

    failureModalInfo: {
      customizeMessage: () => ({
        title: "출고 상세 정보 조회 중에 오류가 발생했습니다.",
      }),
    },
  });

  return queryResult;
}

function useDownloadShippingList({ shippingId }: { shippingId: number }) {
  const mutation = useAppMutation<void, BlobPart>({
    requestOptions: {
      method: "post",
      path: `/shipper/download/${shippingId}`,
      apiType: "BofulDefault",
      responseType: "blob",
    },

    failureModalInfo: {
      customizeMessage: () => ({
        title: "출고 목록을 받는 중에 오류가 발생했습니다.",
      }),
    },
  });

  return mutation;
}

function useGetShippingSkuList({
  shippingId,
  enabled = true,
  onSuccess,
  ...params
}: GET_SHIPPING_SKU_LIST_REQ & {
  enabled?: boolean;
  onSuccess?: (data: GET_SHIPPING_SKU_LIST_RES) => void;
}) {
  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<typeof SHIPPING_QUERY_KEY_GEN.getShippingSkuList>,
    GET_SHIPPING_SKU_LIST_RES
  >({
    queryKey: SHIPPING_QUERY_KEY_GEN.getShippingSkuList({
      shippingId,
      ...params,
    }),
    requestOptions: {
      method: "get",
      path: `/shipper/items/${shippingId}`,
      apiType: "BofulDefault",
      params,
    },
    onSuccess,
    enabled: !!shippingId && enabled,

    failureModalInfo: {
      customizeMessage: (failureInfo) => {
        return {
          title: "원출고 SKU 리스트 조회에 실패했습니다.",
        };
      },
    },
  });

  return { ...queryResult };
}

const SHIPPING_QUERY = {
  useRefineAddress,
  useGetDetailV2,

  useStarPacking,
  useCompletePacking,

  useGetShippingCount,

  useGetShipperList,

  useAddShippingItemV2,

  useGetAdminDetail,
  useGetAdminShippingList,

  useGetPDAShippingList,
  useIssueShippingInvoice,
  useMoveToMyTask,

  useGetCompletedPickingList,
  useScanPickingInvoice,
  useStartPickingScanning,
  useChangePickingLocation,
  useCompletePicking,
  useReportShippingProblem,

  useGetShipmentSummary,
  useGetShipmentToday,
  useGetShipmentDone,
  useGetShipmentDelay,
  useGetShipmentParcelOrder,

  useGetShipmentTruckPackings,
  useCompleteShipmentTruck,

  useInvoiceNumbering,
  useInvoiceNumberingByParcelCompany,
  useGetInvoicePrintingData,

  useUpdateStatusWhenPrintingTransactionStatement,

  useCompleteShipment,

  useUpdateParcelDeliveryDetail,
  useGetTruckDeliveryDetail,
  useUpdateTruckDeliveryDetail,

  useValidateShippingExcelInvoiceList,
  useSaveShippingExcelInvoiceList,

  useGetShippingDetail,
  useUpdateShippingDetailV2,
  useChangeShippingItemsLocations,
  useForceShipping,
  useAddInvoice,

  useCombinePacking,

  useGetShippingListBySkuId,

  useResetShippingStatus,

  useAddShippingAdminMemo,
  useEditDeliveryInfo,

  useGetShippingSearchSuggestionList,

  useValidateDomesticShippingExcel,
  useUploadDomesticShippingExcel,
  useValidateOverseasShippingExcel,
  useUploadOverseasShippingExcel,

  useReorderShipping,

  useAddParcelDetail,

  useGetKyoungdongShippingFreightList,
  useValidateKyoungDongShippingExcelInvoiceList,
  useSaveKyoungDongShippingExcelInvoiceList,

  useCreateForcePicking,
  useEditCustomerDetail,
  useCancelShippingForAdmin,

  useCheckExistingShipping,

  useUpdateShipperMemo,

  useDownloadShippingExcelList,

  useGetShippingSummary,
  useDownloadShippingList,

  useGetShippingSkuList,
};

export default SHIPPING_QUERY;
