import { Dispatch, SetStateAction } from "react";
import { SetterOrUpdater } from "recoil";

import {
  BofulAuthority,
  BofulWorker,
} from "@sellernote/_shared/src/types/fulfillment/auth";
import {
  BofulLocation,
  ClassifiedGroupItem,
  DeliveryTrackingInfoPageType,
  GroupDataByType,
  WarehousingStatusLabel,
} from "@sellernote/_shared/src/types/fulfillment/common";

import { CountryCode, StateCode } from "../../types/common/address";
import {
  FulfillmentChanel,
  FulfillmentParcelCompany,
} from "../../types/fulfillment/fulfillment";
import { ProcessStatus } from "../../types/fulfillment/inspection";
import { ManagementKind } from "../../types/fulfillment/inventory";
import { UserReturningDetailItem } from "../../types/fulfillment/returning";
import { ShippingItem } from "../../types/fulfillment/shipping";
import { ProductGroup, ProductGroupIdItem } from "../../types/fulfillment/sku";

import { IS_UNDER_PRODUCTION } from "../../constants";
import {
  FIRST_GROUP_SKU_ID_IN_DEV,
  FIRST_GROUP_SKU_ID_IN_PROD,
  SALE_CHANNEL_MAP,
} from "../../constants/fulfillment/common";
import { INVENTORY_MANAGEMENT_KIND_MAP } from "../../constants/fulfillment/inventory";
import { COUNTRY_CODE_TYPE } from "../../constants/fulfillment/shipping";
import { toFormattedDate } from "../common/date";
import { getFormattedGroupSkuId, getFormattedSingleSkuId } from "./fulfillment";
import { removeSKUIdPrefix } from "./sku";

/** 매니저일 경우 '이름', 파트타이머일 경우 '계정명'으로 표시 */
function getWorkerNameByAuthority(worker?: BofulWorker) {
  if (!worker) return "";

  return worker.authority === "manager"
    ? worker.name ?? ""
    : worker.accountId ?? "";
}

/** 작업자 리스트에서 담당자명 가져오기 */
function getWorkerNameById(workerList: BofulWorker[], workerId?: number) {
  if (!workerId) return "";

  const worker = workerList.find((worker) => worker.id === workerId);

  if (!worker) {
    return "";
  }
  return getWorkerNameByAuthority(worker);
}

/** 위치 리스트에서 위치명 가져오기 */
function getLocationBarcodeById({
  locationList,
  locationId,
}: {
  locationList: BofulLocation[];
  locationId: number | undefined;
}) {
  return locationList.find((v) => v.id === locationId)?.barCode ?? "";
}

const AuthorityLabelDict: { [key in BofulAuthority]: string } & {
  "": string;
} = {
  admin: "관리자",
  manager: "매니저",
  partTimer: "파트타이머",
  whMaster: "창고 마스터",
  "": "-",
};

/**
 * sku 아이템 종류의 수를 반환
 */
function getSKUItemTypeQuantity(items?: { skuId: number }[]) {
  if (!items) return 0;

  const skuIdSet = new Set(items.map((item) => item.skuId));

  return skuIdSet.size;
}

function getSkuIds(items: { skuId?: number; isGroup?: boolean }[] | undefined) {
  return (items ?? [])
    .map((item) =>
      item.isGroup
        ? getFormattedGroupSkuId(item.skuId)
        : getFormattedSingleSkuId(item.skuId)
    )
    .join(", ");
}

function getPcs(items: { quantity?: number }[]) {
  return items.map((item) => item.quantity).join(", ");
}

/**
 * 그룹상품을 items에 리스팅 하기위한 형태로 변환
 */
function getGroupItems(
  groupDataByType: GroupDataByType
): ClassifiedGroupItem[] {
  if (groupDataByType.type === "list") {
    return (groupDataByType.productGroupIds ?? []).map((productGroupIdItem) => {
      const { productGroupId, productGroupName, groupItems } =
        productGroupIdItem;

      const quantity = groupItems
        .map((groupItem) => groupItem.qty)
        .reduce((acc, cur) => acc + cur, 0);

      return {
        isGroup: true,

        id: productGroupId,
        skuId: productGroupId,
        quantity,
        sku: {
          id: productGroupId,
          itemName: productGroupName,
        },
      };
    });
  }

  if (groupDataByType.type === "detail") {
    return (groupDataByType.groups ?? []).map((groupItem) => {
      const {
        id,
        qty,
        groupName,
        productCode,
        managementCode,
        materialPackageType,
        returningCondition,
        attachment,
        packages,
        groupItems,
      } = groupItem;

      return {
        isGroup: true,

        id,
        skuId: id,
        quantity: qty,
        sku: {
          id,
          itemName: groupName,
          productCode,
          managementCode,
          materialPackageType,
          returningCondition,
          attachment,
          packages,
        },
        groupItems,
      };
    });
  }

  return [];
}

/**
 * TODO: V2는 백엔드 개선으로 인해 변경된 API만 적용됨
 * TODO: 반품까지 백엔드 개선이 완료될 경우 V2 문구를 삭제하고 기존 함수, 컴포넌트를 삭제
 * 그룹상품을 items에 리스팅 하기위한 형태로 변환
 */
const getGroupItemsV2 = (
  groupList: ProductGroup[] | undefined
): ClassifiedGroupItem[] =>
  (groupList ?? []).map((groupData) => {
    const {
      id,
      qty,
      groupName,
      productCode,
      managementCode,
      materialPackageType,
      returningCondition,
      attachment,
      packages,
      groupItems,
    } = groupData;

    return {
      isGroup: true,

      id,
      skuId: id,
      quantity: qty,
      sku: {
        id,
        itemName: groupName,
        productCode,
        managementCode,
        materialPackageType,
        returningCondition,
        attachment,
        packages,
      },
      groupItems,
    };
  });

/**
 * 그룹상품의 각 구성상품 정보를 총수량(그룹상품의 수량 * 구성상품의 수량)과 함께 반환
 */
const getGroupItemsWithTotalQuantity = (groupDataByType: GroupDataByType) =>
  getGroupDataByType(groupDataByType).flatMap((groupDataItem) =>
    (groupDataItem.groupItems ?? []).map((groupItem) => ({
      ...groupItem,
      qty: groupItem.qty * groupDataItem.qty,
    }))
  );

/**
 * TODO: V2는 백엔드 개선으로 인해 변경된 API만 적용됨
 * TODO: 반품까지 백엔드 개선이 완료될 경우 V2 문구를 삭제하고 기존 함수, 컴포넌트를 삭제
 * 그룹상품의 각 구성상품 정보를 총수량(그룹상품의 수량 * 구성상품의 수량)과 함께 반환
 */
const getGroupItemsWithTotalQuantityV2 = (
  groupList: ProductGroup[] | undefined
) =>
  (groupList ?? []).flatMap((groupData) =>
    (groupData.groupItems ?? []).map((groupItem) => ({
      ...groupItem,
      qty: groupItem.qty * groupData.qty,
    }))
  );

// TODO: 최종적으로 반품까지 백엔드 개선 내용이 반영되면 삭제해야 됨
const getGroupDataByType = (groupData: GroupDataByType) => {
  if (groupData.type === "list") {
    return groupData.productGroupIds ?? [];
  }

  if (groupData.type === "detail") {
    return groupData.groups ?? [];
  }

  return [];
};

/**
 * 그룹상품의 구성상품 SKU ID 목록을 반환(구성상품 간 중복이 있을 수 있으므로 Set으로 반환)
 */
const getDetailSkuIdList = (groupItems: { skuId: number }[] | undefined) =>
  new Set((groupItems ?? []).map(({ skuId }) => skuId));

/**
 * items에서 구성상품의 수량(quantity)을 뺀 items를 반환
 */
const getItemsWithoutGroupItemsQuantity = <
  T extends ShippingItem | UserReturningDetailItem
>({
  items,
  detailSkuIdList,
  groupItemsWithTotalQuantity,
}: {
  items: T[] | undefined;
  detailSkuIdList: Set<number>;
  groupItemsWithTotalQuantity: { skuId: number; qty: number }[];
}) => {
  const getItemWithoutGroupItemsQuantity = (item: T) => {
    if (detailSkuIdList.has(item.skuId)) {
      const totalQuantityBySkuId = groupItemsWithTotalQuantity.reduce(
        (totalQuantity, groupItem) => {
          if (item.skuId === groupItem.skuId) {
            return totalQuantity + groupItem.qty;
          }

          return totalQuantity;
        },
        0
      );

      return {
        ...item,
        quantity: item.quantity - totalQuantityBySkuId,
      };
    }

    return item;
  };

  return (items ?? []).map(getItemWithoutGroupItemsQuantity);
};

const accumulateQuantity = (acc: number, item: { quantity?: number }) =>
  acc + (item.quantity ?? 0);

const reduceQuantity = (items: { quantity?: number }[] | undefined) =>
  (items ?? []).reduce(accumulateQuantity, 0);

/**
 * SKU ID가 2개 이상인 경우 '외 n건'으로 표시 ex) S3 외 1건
 */
const getSkuIdListForAdmin = (
  items: { skuId?: number; isGroup?: boolean }[] | undefined
) => {
  const skuIdList = (items ?? []).map(getFormattedSkuId);

  if (skuIdList.length > 1) {
    return `${skuIdList[0]} 외 ${skuIdList.length - 1}개`;
  }

  return skuIdList[0];
};

/**
 * 주문수량(ORDER) - classify된 items를 기준
 */
const getOrderQuantity = (items: { quantity?: number }[] | undefined) =>
  reduceQuantity(items);

type GetShippingQuantityParams = {
  items: { skuId?: number; quantity?: number; isGroup?: boolean }[] | undefined;
} & (
  | {
      type: "shipda";
    }
  | {
      type: "admin";
      productGroupIds: ProductGroupIdItem[] | undefined;
    }
);
/**
 * 출고수량(PCS)
 * - Shipda: 재고가 차감되는 단일상품 기준이므로 classify하기 전 원래 items를 기준
 * - Admin: classify된 items + productGroupIds를 조합
 */
const getShippingQuantity = ({
  items,
  ...paramsByType
}: GetShippingQuantityParams) => {
  if (paramsByType.type === "shipda") {
    return reduceQuantity(items);
  }

  if (paramsByType.type === "admin") {
    const { productGroupIds } = paramsByType;

    return (items ?? []).reduce((acc, item) => {
      if (item.isGroup) {
        const targetGroup = (productGroupIds ?? []).find(
          (productGroupIdItem) =>
            productGroupIdItem.productGroupId === item.skuId
        );

        if (targetGroup) {
          const targetGroupTotalQuantity = (
            targetGroup.groupItems ?? []
          ).reduce(
            (acc, groupItem) => acc + groupItem.qty * targetGroup.qty,
            0
          );

          return acc + targetGroupTotalQuantity;
        }

        return acc;
      }

      return acc + (item.quantity ?? 0);
    }, 0);
  }

  return 0;
};

type GetShippingQuantityParamsV2 = {
  items: { skuId?: number; quantity?: number; isGroup?: boolean }[] | undefined;
} & (
  | {
      type: "shipda";
    }
  | {
      type: "admin";
      groupList: ProductGroup[] | undefined;
    }
);
/**
 * TODO: V2는 백엔드 개선으로 인해 변경된 API만 적용됨
 * TODO: 반품까지 백엔드 개선이 완료될 경우 V2 문구를 삭제하고 기존 함수, 컴포넌트를 삭제
 * 출고수량(PCS)
 * - Shipda: 재고가 차감되는 단일상품 기준이므로 classify하기 전 원래 items를 기준
 * - Admin: classify된 items + groupList를 조합
 */
const getShippingQuantityV2 = ({
  items,
  ...paramsByType
}: GetShippingQuantityParamsV2) => {
  if (paramsByType.type === "shipda") {
    return reduceQuantity(items);
  }

  if (paramsByType.type === "admin") {
    const { groupList } = paramsByType;

    return (items ?? []).reduce((acc, item) => {
      if (item.isGroup) {
        const targetGroup = (groupList ?? []).find(
          (groupData) => groupData.id === item.skuId
        );

        if (targetGroup) {
          const targetGroupTotalQuantity = (
            targetGroup.groupItems ?? []
          ).reduce(
            (acc, groupItem) => acc + groupItem.qty * targetGroup.qty,
            0
          );

          return acc + targetGroupTotalQuantity;
        }

        return acc;
      }

      return acc + (item.quantity ?? 0);
    }, 0);
  }

  return 0;
};

const getShippingQuantityForShipdaCSV = ({
  item,
  skuId,
  productGroupIds,
}: {
  item: { quantity?: number; isGroup?: boolean };
  skuId: number;
  productGroupIds: ProductGroupIdItem[] | undefined;
}) => {
  if (!item) {
    return 0;
  }

  if (!item.isGroup) {
    return item.quantity ?? 0;
  }

  const targetGroup = (productGroupIds ?? []).find(
    (group) => group.productGroupId === skuId
  );

  if (!targetGroup) {
    return 0;
  }

  return (targetGroup.groupItems ?? []).reduce(
    (acc, groupItem) => acc + groupItem.qty * targetGroup.qty,
    0
  );
};

/**
 * TODO: V2는 백엔드 개선으로 인해 변경된 API만 적용됨
 * TODO: 반품까지 백엔드 개선이 완료될 경우 V2 문구를 삭제하고 기존 함수, 컴포넌트를 삭제
 */
const getShippingQuantityForShipdaCSVV2 = ({
  item,
  skuId,
  groupList,
}: {
  item: { quantity?: number; isGroup?: boolean };
  skuId: number;
  groupList: ProductGroup[] | undefined;
}) => {
  if (!item) {
    return 0;
  }

  if (!item.isGroup) {
    return item.quantity ?? 0;
  }

  const targetGroup = (groupList ?? []).find((group) => group.id === skuId);

  if (!targetGroup) {
    return 0;
  }

  return (targetGroup.groupItems ?? []).reduce(
    (acc, groupItem) => acc + groupItem.qty * targetGroup.qty,
    0
  );
};

const getFormattedSkuId = (item: { skuId?: number; isGroup?: boolean }) => {
  if (!item) {
    return "";
  }

  if (item.isGroup) {
    return getFormattedGroupSkuId(item.skuId);
  }

  return getFormattedSingleSkuId(item.skuId);
};

const checkHasQuantity = (item: { quantity?: number }) => {
  if (!item.quantity) {
    return false;
  }

  return item.quantity > 0;
};

/**
 * 수량이 존재하는 아이템만 필터링
 */
const getFilteredItemsByQuantity = <T extends { quantity: number }>(
  items: T[] | undefined
) => (items ?? []).filter(checkHasQuantity);

/**
 * productGroupIds에는 구성상품의 상품명이 없기 때문에 items에서 찾아서 추가
 */
const getProductGroupIdsForTooltip = ({
  productGroupIds,
  items,
}: {
  productGroupIds: ProductGroupIdItem[] | undefined;
  items: { skuId?: number; sku: { itemName?: string } }[] | undefined;
}) =>
  (productGroupIds ?? []).map((productGroupIdItem) => ({
    ...productGroupIdItem,
    groupItems: (productGroupIdItem.groupItems ?? []).map((groupItem) => ({
      ...groupItem,
      sku: {
        itemName: (items ?? []).find((item) => item.skuId === groupItem.skuId)
          ?.sku.itemName,
      },
    })),
  }));

/**
 * TODO: V2는 백엔드 개선으로 인해 변경된 API만 적용됨
 * TODO: 반품까지 백엔드 개선이 완료될 경우 V2 문구를 삭제하고 기존 함수, 컴포넌트를 삭제
 * productGroupIds에는 구성상품의 상품명이 없기 때문에 items에서 찾아서 추가
 */
const getProductGroupIdsForTooltipV2 = ({
  groupList,
  items,
}: {
  groupList: ProductGroup[] | undefined;
  items: { skuId?: number; sku: { itemName?: string } }[] | undefined;
}) =>
  (groupList ?? []).map((groupData) => ({
    ...groupData,
    groupItems: (groupData.groupItems ?? []).map((groupItem) => ({
      ...groupItem,
      sku: {
        itemName: (items ?? []).find((item) => item.skuId === groupItem.skuId)
          ?.sku.itemName,
      },
    })),
  }));

const getBarcodeList = (
  items: { sku?: { barCode?: string } }[] | undefined
) => {
  const barcodeList = (items ?? [])
    .map((item) => item.sku?.barCode)
    .filter((barcode) => barcode);

  if (!barcodeList.length) return "-";

  // barcodeList의 타입이 string | undefined가 아닌 string | null 이지만
  // 이걸 지금 수정하면 타입이 전체적으로 깨질것으로 예상되기 때문에 강제로 (string | null)[] 타입으로 설정
  if ((barcodeList as (string | null)[]).includes(null)) return "-";

  if (barcodeList.length > 2) {
    return `${barcodeList[0]}, ${barcodeList[1]} 외 ${
      barcodeList.length - 2
    }개`;
  }

  return barcodeList.join(", ");
};

const checkIsSupportedParcelCompany = ({
  pageName,
  parcelCompany,
}: {
  pageName: DeliveryTrackingInfoPageType;
  parcelCompany: FulfillmentParcelCompany | undefined;
}) => {
  if (!parcelCompany) {
    return false;
  }

  const isShipping = pageName === "shipping";
  const isReturning = pageName === "returning";

  const isCj = parcelCompany === "cj";
  const isHanjin = parcelCompany === "hanjin";
  const isKunyoung = parcelCompany === "kunyoung";
  const isKyoungdong = parcelCompany === "kd";

  if (isShipping) {
    return isCj || isKyoungdong || isKunyoung;
  }

  if (isReturning) {
    return isCj || isHanjin;
  }

  return false;
};

const checkIsGroupSkuItem = (skuId: number | undefined) => {
  if (!skuId) {
    return false;
  }

  return (
    skuId >=
    (IS_UNDER_PRODUCTION
      ? FIRST_GROUP_SKU_ID_IN_PROD
      : FIRST_GROUP_SKU_ID_IN_DEV)
  );
};

/**
 * skuId가 같더라도 별도의 item으로 존재하는 경우 skuId 기준으로 합쳐주기 위한 함수(ex. location이 다른 경우 등)
 */
const mergeItemsBySkuId = <T extends { skuId: number; quantity: number }>(
  items: T[] | undefined
) =>
  (items ?? []).reduce((prevItems, item) => {
    const existingItemIndex = prevItems.findIndex(
      (prevItem) => prevItem.skuId === item.skuId
    );

    if (existingItemIndex === -1) {
      return [...prevItems, item];
    }

    return prevItems.map((prevItem, index) => {
      if (index === existingItemIndex) {
        return {
          ...prevItem,
          quantity: prevItem.quantity + item.quantity,
        };
      }

      return prevItem;
    });
  }, [] as T[]);

/**
 * 테이블 컬럼 필터 값에 따라 Search Query Params를 업데이트 (필터 값 복수 선택)
 * @param filterKey Query String의 key로 전달되는 컬럼 필터 key 값
 * @param filterValue Query String의 value로 전달되는 컬럼 필터 value 값
 * @param setSearchQueryParams Query String setter 함수
 */
function updateSearchQueryParams<
  RequestType,
  FilterKey extends keyof RequestType,
  FilterValue
>({
  filterKey,
  filterValue,
  setSearchQueryParams,
}: {
  filterKey: FilterKey;
  filterValue: FilterValue[];
  setSearchQueryParams: SetterOrUpdater<RequestType>;
}) {
  filterValue?.length &&
    setSearchQueryParams((prev) => ({
      ...prev,
      [filterKey]: filterValue,
      page: 0, // 필터 값 변경 시 첫 페이지로
    }));

  !filterValue?.length &&
    setSearchQueryParams((prev) => {
      const newSearchQueryParams = { ...prev, page: 0 }; // 필터 값 변경 시 첫 페이지로
      delete newSearchQueryParams[filterKey];
      return newSearchQueryParams;
    });
}
const getFormattedSearchItem = ({
  searchKindLabel,
  searchTerm,
}: {
  searchKindLabel: string;
  searchTerm: string;
}) => `${searchKindLabel}: ${searchTerm}`;

/**
 * SearchSelector 선택 상태 변경 핸들러
 */
function handleSearchStateUpdate<OptionValue, ObjectFormState>({
  key,
  value,
  setState,
}: {
  key: keyof ObjectFormState;
  value: OptionValue | null;
  setState: Dispatch<SetStateAction<ObjectFormState>>;
}) {
  setState((prev) => ({ ...prev, [key]: value }));
}

type DomesticAddress = {
  type: "domestic";
  customerAddress: string | undefined;
  customerDetailAddress: string | undefined;
};
type OverseasAddressForShipda = {
  type: "overseasForShipda";
  /** 해외(상세주소) */
  customerAddress: string | undefined;
  customerCountryCode: CountryCode | undefined;
  customerPostalCode: string | undefined;
};
type OverseasAddressForAdmin = {
  type: "overseasForAdmin";
  /** 해외(상세주소) */
  customerAddress: string | undefined;
  customerStateCode: StateCode | undefined;
  /** 해외(도시명) */
  customerDetailAddress: string | undefined;
};
const getFormattedCustomerAddress = (
  paramsByType:
    | DomesticAddress
    | OverseasAddressForShipda
    | OverseasAddressForAdmin
) => {
  if (paramsByType.type === "domestic") {
    const { customerAddress, customerDetailAddress } = paramsByType;

    return `${customerAddress ?? ""} ${customerDetailAddress ?? ""}`;
  }

  if (paramsByType.type === "overseasForAdmin") {
    const { customerAddress, customerStateCode, customerDetailAddress } =
      paramsByType;

    return `${customerAddress ?? ""}(${`${
      customerStateCode ? `${customerStateCode}, ` : ""
    }`}${customerDetailAddress ?? ""})`;
  }

  if (paramsByType.type === "overseasForShipda") {
    const { customerAddress, customerCountryCode, customerPostalCode } =
      paramsByType;

    return `${customerAddress ?? ""} (${
      customerCountryCode ? COUNTRY_CODE_TYPE[customerCountryCode] : ""
    }, ${customerPostalCode ?? ""})`;
  }

  return "";
};

const getPayloadByFilter = <T extends unknown>({
  payloadList,
  key,
  isSingle,
}: {
  payloadList: T[];
  key: string;
  isSingle?: boolean;
}) => {
  if (!payloadList.length) {
    return {};
  }

  if (isSingle) {
    return {
      [key]: payloadList[0],
    };
  }

  return {
    [key]: payloadList,
  };
};

const getChannelName = ({
  channelDict,
  shippingItem,
}: {
  channelDict: { [x: string]: FulfillmentChanel };
  shippingItem:
    | {
        channelId: number;
        channel?: string;
      }
    | undefined;
}) => {
  if (!shippingItem) {
    return "";
  }

  const { channelId, channel } = shippingItem;

  return channelDict[channelId] ? channelDict[channelId].korName : channel;
};

const getTeamLabelForBofulWorker = (
  team:
    | {
        id?: number;
        name?: string;
        company?: string;
      }
    | undefined
) => {
  if (!team) {
    return "-";
  }

  return `${team?.company || "-"} (${team?.name || "-"} / ${team?.id || "-"})`;
};

const formatNumberWithComma = (value: string | undefined) => {
  if (!value) {
    return "";
  }

  const [integerPart, decimalPart] = value.split(".");
  const integerWithComma = new Intl.NumberFormat("en-US").format(
    Number(integerPart)
  );

  if (value.endsWith(".")) {
    return integerWithComma + ".";
  }

  return `${integerWithComma}${decimalPart ? `.${decimalPart}` : ""}`;
};

const getNeedConfirmBeforeCloseModalInfo = (isEditMode = false) => ({
  title: (
    <>
      입력을 중단하고 창을 닫으시겠습니까?
      <br />
      {isEditMode ? "수정" : "등록"} 요청 버튼을 누르지 않으면 작성된 내용은
      저장되지 않습니다.
    </>
  ),
  actionPositiveLabel: "페이지나가기",
  actionNegativeLabel: "이어서입력",
});

const getSaleChannelKorName = (saleChannelName: string | undefined) => {
  if (!saleChannelName) {
    // default 값이 etc이므로 값이 존재해야 하나, 없을 경우 명시적으로 보여주기 위해 추가
    return "판매채널 없음";
  }

  return SALE_CHANNEL_MAP[saleChannelName] ?? saleChannelName;
};

/**
 * PDA > 입고/반품관리 > 입고 > 상품의 상태 라벨을 반환
 */
const getWarehousingItemStatusLabel = (
  processStatus: ProcessStatus | undefined
): WarehousingStatusLabel => (processStatus === "faulty" ? "불량" : "정상");

/**
 * PDA > 입고/반품관리 > subRow로 나뉘어진 아이템인지 확인
 */
const checkIsGroupedItem = (
  itemList: { sku?: { id: number; barCode?: string } }[],
  /** SKU ID 또는 상품바코드 */
  target: string | number | undefined
) =>
  (itemList ?? []).filter((item) => {
    if (!item.sku) {
      return false;
    }

    return (
      item.sku.id === Number(removeSKUIdPrefix(target)) ||
      item.sku.barCode === String(target)
    );
  }).length > 1;

/**
 * PDA > 입고/반품관리 > 입고 > 동일한 SKU ID가 존재하는 상품의 SKU ID리스트를 구하기 (groupedItem은 id를 음수로 가지고 있음)
 */
const getGroupedItemSkuIdList = (
  warehousingList: { id: number; sku: { id: number } }[]
) =>
  warehousingList.reduce<number[]>((acc, cur) => {
    if (cur.id < 0) {
      return [...acc, cur.sku.id];
    }

    return acc;
  }, []);

/**
 * PDA > 입고/반품관리 > 입고 > targetSkuId가 동일한 SKU ID가 존재하는 상품인지 확인(groupedItem은 id를 음수로 가지고 있음)
 */
const checkIsGroupedItemBySkuId = (
  groupedItemSkuIdList: number[],
  targetSkuId: number
) => groupedItemSkuIdList.includes(targetSkuId);

const changeScanModeToLocationOnFullCount = ({
  setScanMode,
  count,
  max,
}: {
  setScanMode: (v: "skuId" | "location") => void;
  count: number;
  max: number | undefined;
}) => {
  const isSkuCountingComplete = count === max;
  if (isSkuCountingComplete) {
    setScanMode("location");
  }
};

/**
 * 풀필먼트의 itemList를 SKU로 묶인 Map형태로 변환
 */
function convertItemListToMapBySKU<T extends { skuId: number }>(
  source: T[] | undefined
): Record<number, T[]> | null {
  if (!source || !source?.length) {
    return null;
  }

  return source.reduce<Record<number, T[]>>((a, c) => {
    const existedValue = a[c.skuId] || [];

    a[c.skuId] = [...existedValue, c];

    return a;
  }, {});
}

/**
 * SKU ID와 관리일자가 합쳐진 label을 생성
 * - case1: 관리일자 정보가 없는 경우: ex) `SKU ID: S1201002`
 * - case2: 관리일자 정보가 있는 경우: ex) `SKU ID: S1201002 / 유통기한: 2024-03-14`
 */
function getLabelCombinedWithSKUIdAndManagementDate({
  SKUId,
  formattedSKUId,
  managementKind,
  managementDate,
}: {
  SKUId?: number;
  /**
   * `S12102`처럼 S prefix가 붙는 string 형식인 경우만 이 값을 사용한다.
   * 이 값을 보내면 `SKUId`로 보내는 값은 무시된다. (= SKUId은 보낼 필요없다)
   **/
  formattedSKUId?: string;
  managementKind?: ManagementKind;
  managementDate?: string;
}) {
  const normalizedSKUId = (() => {
    if (!SKUId && !formattedSKUId) return "-";

    // 이미 format된 값이므로 바로 return
    if (formattedSKUId) return formattedSKUId;

    return getFormattedSingleSkuId(SKUId);
  })();

  const SKUIdLabel = `SKU ID: ${normalizedSKUId}`;

  const usesManagementDate = checkUsesManagementDate({
    managementKind,
    managementDate,
  });

  return usesManagementDate
    ? `${SKUIdLabel} / ${getManagementDateLabel(
        managementKind,
        managementDate
      )}`
    : SKUIdLabel;
}

/**
 * 관리일자 타입과 날짜가 결합된 label을 생성
 * (ex. `유통기한: 2024-03-14`)
 */
function getManagementDateLabel(
  managementKind?: ManagementKind,
  managementDate?: string
) {
  if (!(managementKind && managementDate)) return "";

  return `${INVENTORY_MANAGEMENT_KIND_MAP[managementKind]}: ${toFormattedDate(
    managementDate,
    "YYYY-MM-DD"
  )}`;
}

const checkUsesManagementDate = ({
  managementKind,
  managementDate,
}: {
  managementKind: ManagementKind | undefined;
  managementDate: string | undefined;
}) => Boolean(managementKind) && Boolean(managementDate);

const getNumberParam = (search: string, paramName: string) =>
  Number(new URLSearchParams(search).get(paramName)) || 0;

export {
  AuthorityLabelDict,
  getSKUItemTypeQuantity,
  getWorkerNameByAuthority,
  getLocationBarcodeById,
  getWorkerNameById,
  getSkuIds,
  getPcs,
  getGroupItems,
  getGroupItemsV2,
  getGroupItemsWithTotalQuantity,
  getGroupItemsWithTotalQuantityV2,
  getGroupDataByType,
  getDetailSkuIdList,
  getItemsWithoutGroupItemsQuantity,
  accumulateQuantity,
  reduceQuantity,
  getSkuIdListForAdmin,
  getOrderQuantity,
  getShippingQuantity,
  getShippingQuantityV2,
  getShippingQuantityForShipdaCSV,
  getShippingQuantityForShipdaCSVV2,
  getFormattedSkuId,
  checkHasQuantity,
  getFilteredItemsByQuantity,
  getProductGroupIdsForTooltip,
  getProductGroupIdsForTooltipV2,
  getBarcodeList,
  checkIsSupportedParcelCompany,
  checkIsGroupSkuItem,
  mergeItemsBySkuId,
  updateSearchQueryParams,
  getFormattedSearchItem,
  handleSearchStateUpdate,
  getFormattedCustomerAddress,
  getPayloadByFilter,
  getChannelName,
  getTeamLabelForBofulWorker,
  formatNumberWithComma,
  getSaleChannelKorName,
  getNeedConfirmBeforeCloseModalInfo,
  getWarehousingItemStatusLabel,
  checkIsGroupedItem,
  getGroupedItemSkuIdList,
  checkIsGroupedItemBySkuId,
  changeScanModeToLocationOnFullCount,
  convertItemListToMapBySKU,
  getLabelCombinedWithSKUIdAndManagementDate,
  getManagementDateLabel,
  checkUsesManagementDate,
  getNumberParam,
};
