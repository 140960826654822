import {
  MutationSideEffectType,
  useAppMutation,
  useAppQueryWithQueryKeyFactory,
} from "@sellernote/_shared/src/services/query";

import {
  ManagerReturningListItem,
  ReturningList,
  ReturningListItem,
} from "../../types/fulfillment/returning";

import { QueryResponseHandlerSuccessModalInfo } from "../../components/QueryResponseHandler";

import {
  ADD_PARTIAL_WAREHOUSING_REQ,
  ASSIGN_INSPECTOR_TO_ITEM_REQ_PATH_PARAMS,
  ASSIGN_PLACER_TO_ITEM_REQ,
  ASSIGN_PLACER_TO_ITEM_REQ_PATH_PARAMS,
  CANCEL_RETURNING_REQ,
  EDIT_SENDER_DETAIL_REQ,
  GET_ADMIN_RETURNING_DETAIL_REQ,
  GET_ADMIN_RETURNING_DETAIL_RES,
  GET_ADMIN_RETURNING_LIST_FOR_CSV_DOWNLOAD_REQ,
  GET_ADMIN_RETURNING_LIST_FOR_CSV_DOWNLOAD_RES,
  GET_ADMIN_RETURNING_LIST_REQ,
  GET_ADMIN_RETURNING_LIST_RES,
  GET_MANAGER_RETURNING_LIST_FOR_INSPECTION_REQ,
  GET_MANAGER_RETURNING_LIST_REQ,
  GET_PDA_RETURNING_LIST_REQ,
  GET_PDA_RETURNING_LIST_RES,
  GET_RETURNING_INPROGRESS_DETAIL_REQ,
  GET_RETURNING_INPROGRESS_DETAIL_RES,
  GET_RETURNING_INPROGRESS_LIST_FOR_CSV_DOWNLOAD_REQ,
  GET_RETURNING_INPROGRESS_LIST_FOR_CSV_DOWNLOAD_RES,
  GET_RETURNING_INPROGRESS_LIST_REQ,
  GET_RETURNING_INPROGRESS_LIST_RES,
  GET_RETURNING_INSPECT_DETAIL_REQ,
  GET_RETURNING_INSPECT_DETAIL_RES,
  GET_RETURNING_REQUEST_LIST_FOR_CSV_DOWNLOAD_REQ,
  GET_RETURNING_REQUEST_LIST_FOR_CSV_DOWNLOAD_RES,
  GET_RETURNING_REQUEST_LIST_REQ,
  GET_RETURNING_REQUEST_LIST_RES,
  REMOVE_PARTIAL_WAREHOUSING_REQ,
  REMOVE_PARTIAL_WAREHOUSING_REQ_PATH_PARAMS,
  REPORT_INSPECTION_PROBLEM_REQ,
  REPORT_WAREHOUSING_PROBLEM_REQ,
  RESET_INSPECTION_OF_ITEM_EXPECT_INSPECTOR_REQ_PATH_PARAMS,
  RESET_INSPECTOR_AND_INSPECTION_OF_ITEM_REQ_PATH_PARAMS,
  RESET_PLACER_AND_WAREHOUSING_OF_ITEM_REQ,
  RESET_PLACER_AND_WAREHOUSING_OF_ITEM_REQ_PATH_PARAMS,
  RESET_WAREHOUSING_OF_ITEM_EXPECT_PLACER_REQ,
  RESET_WAREHOUSING_OF_ITEM_EXPECT_PLACER_REQ_PARAMS,
  RETURNING_REQUEST_REQ,
  RETURNING_REQUEST_REQ_PATH_PARAMS,
  SET_ADMIN_PROCESS_STATUS_TO_DISPOSAL_REQ_PATH_PARAMS,
  SET_ADMIN_PROCESS_STATUS_TO_FORWARD_REQ_PATH_PARAMS,
  SET_ADMIN_PROCESS_STATUS_TO_RESTOCK_REQ_PATH_PARAMS,
  SET_INSPECTION_DONE_REQ,
  SET_INSPECTION_STARTED_REQ_PATH_PARAMS,
  SET_ITEM_INSPECTION_DONE_BY_FORCE_REQ_PATH_PARAMS,
  SET_ITEM_INSPECTION_DONE_REQ,
  SET_ITEM_INSPECTION_DONE_REQ_PATH_PARAMS,
  SET_ITEM_WAREHOUSING_DONE_BY_FORCE_REQ_PATH_PARAMS,
  SET_ITEM_WAREHOUSING_DONE_REQ,
  SET_ITEM_WAREHOUSING_DONE_REQ_PATH_PARAMS,
  SET_WAREHOUSING_DONE_REQ,
  SUBMIT_RETURNING_INVOICE_NO_REQ,
  SUBMIT_RETURNING_INVOICE_NO_RES,
  UPDATE_AGREEMENT_FOR_INSPECTION_REQ,
  UPDATE_AGREEMENT_FOR_INSPECTION_RES,
  UPDATE_MANAGEMENT_DATE_OF_UNVERIFIED_ITEM_REQ,
  UPDATE_MANAGEMENT_DATE_OF_UNVERIFIED_ITEM_RES,
} from "../../api-interfaces/boful-api/returning";
import { COLOR } from "../../stylesToMoveToV1/constants";

export const RETURNING_QUERY_KEY_GEN = {
  all: () => [{ scope: "fulfillment/RETURNING_QUERY" }] as const,

  adminReturning: () =>
    [
      {
        ...RETURNING_QUERY_KEY_GEN.all()[0],
        subScope: "adminReturning",
      },
    ] as const,

  getReturningRequestList: (params: GET_RETURNING_REQUEST_LIST_REQ) =>
    [
      {
        ...RETURNING_QUERY_KEY_GEN.all()[0],
        ...params,
      },
    ] as const,
  getReturningRequestListForCSVDownload: (
    params: GET_RETURNING_REQUEST_LIST_FOR_CSV_DOWNLOAD_REQ
  ) =>
    [
      {
        ...RETURNING_QUERY_KEY_GEN.all()[0],
        ...params,
        entity: "returningRequestListForCSVDownload",
      },
    ] as const,

  getManagerReturningList: (params: GET_MANAGER_RETURNING_LIST_REQ) =>
    [
      {
        ...RETURNING_QUERY_KEY_GEN.all()[0],
        ...params,
        entity: "managerReturningList",
      },
    ] as const,

  getManagerReturningListForInspection: (
    params: GET_MANAGER_RETURNING_LIST_FOR_INSPECTION_REQ
  ) =>
    [
      {
        ...RETURNING_QUERY_KEY_GEN.all()[0],
        ...params,
        entity: "managerReturningListForInspection",
      },
    ] as const,

  getReturningDetail: (params: GET_RETURNING_INPROGRESS_DETAIL_REQ) =>
    [
      {
        ...RETURNING_QUERY_KEY_GEN.all()[0],
        entity: "returningDetail",
        ...params,
      },
    ] as const,

  getAdminReturningList: (params: GET_ADMIN_RETURNING_LIST_REQ) =>
    [
      {
        ...RETURNING_QUERY_KEY_GEN.adminReturning()[0],
        ...params,
        entity: "adminReturningList",
      },
    ] as const,

  getAdminReturningListForCSVDownload: (
    params: GET_ADMIN_RETURNING_LIST_FOR_CSV_DOWNLOAD_REQ
  ) =>
    [
      {
        ...RETURNING_QUERY_KEY_GEN.all()[0],
        ...params,
        entity: "adminReturningListForCSVDownload",
      },
    ] as const,

  getAdminReturningDetail: (params: GET_ADMIN_RETURNING_DETAIL_REQ) =>
    [
      {
        ...RETURNING_QUERY_KEY_GEN.adminReturning()[0],
        ...params,
        entity: "adminReturningDetail",
      },
    ] as const,

  getReturningInprogressList: (params: GET_RETURNING_INPROGRESS_LIST_REQ) =>
    [
      {
        ...RETURNING_QUERY_KEY_GEN.all()[0],
        ...params,
        entity: "returningInprogressList",
      },
    ] as const,
  getReturningInprogressListForCSVDownload: (
    params: GET_RETURNING_INPROGRESS_LIST_FOR_CSV_DOWNLOAD_REQ
  ) =>
    [
      {
        ...RETURNING_QUERY_KEY_GEN.all()[0],
        ...params,
        entity: "returningInprogressListForCSVDownload",
      },
    ] as const,

  getPDAReturningDetail: (params: GET_PDA_RETURNING_LIST_REQ) =>
    [
      {
        ...RETURNING_QUERY_KEY_GEN.all()[0],
        entity: "returningDetailPDA",
        ...params,
      },
    ] as const,

  getReturningInspectDetail: (params: GET_RETURNING_INSPECT_DETAIL_REQ) =>
    [
      {
        ...RETURNING_QUERY_KEY_GEN.all()[0],
        entity: "returningInspectDetail",
        ...params,
      },
    ] as const,
};

function useGetReturningRequestList(params: GET_RETURNING_REQUEST_LIST_REQ) {
  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<typeof RETURNING_QUERY_KEY_GEN.getReturningRequestList>,
    GET_RETURNING_REQUEST_LIST_RES
  >({
    queryKey: RETURNING_QUERY_KEY_GEN.getReturningRequestList(params),
    requestOptions: {
      method: "get",
      path: `/shipper/returning`,
      apiType: "BofulDefault",
      params,
    },

    failureModalInfo: {
      customizeMessage: (failureInfo) => {
        if (failureInfo?.errorCode === "E4200")
          return {
            title: "검색 조건과 일치하지 않습니다.",
          };

        if (failureInfo?.errorCode === "E4201")
          return {
            title: "같이 검색할 수 없는 조건입니다.",
          };

        if (failureInfo?.errorCode === "E4202")
          return {
            title: "상품 검색은 완전 검색만 가능합니다.",
          };

        return {
          title: "반품신청/취소 리스트 조회 중에 오류가 발생했습니다.",
        };
      },
    },
  });

  return { ...queryResult };
}

function useGetReturningRequestListForCSVDownload({
  enabled,
  ...params
}: GET_RETURNING_REQUEST_LIST_FOR_CSV_DOWNLOAD_REQ & {
  enabled?: boolean;
}) {
  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<
      typeof RETURNING_QUERY_KEY_GEN.getReturningRequestListForCSVDownload
    >,
    GET_RETURNING_REQUEST_LIST_FOR_CSV_DOWNLOAD_RES
  >({
    queryKey:
      RETURNING_QUERY_KEY_GEN.getReturningRequestListForCSVDownload(params),
    requestOptions: {
      method: "get",
      path: "/shipper/returning",
      apiType: "BofulDefault",
      params,
    },

    ...(typeof enabled === "boolean" ? { enabled } : {}),
  });

  return { ...queryResult };
}

function useRequestReturning({ onSuccess }: { onSuccess?: () => void }) {
  const mutation = useAppMutation<RETURNING_REQUEST_REQ, unknown>({
    requestOptions: {
      method: "post",
      path: `/returning`,
      apiType: "BofulDefault",
    },

    onSuccess,

    failureModalInfo: {
      customizeMessage: () => ({
        title: `반품 신청 중에 오류가 발생했습니다.`,
      }),
    },
  });

  return mutation;
}

function useUpdateRequestReturning({ onSuccess }: { onSuccess?: () => void }) {
  const mutation = useAppMutation<
    RETURNING_REQUEST_REQ,
    unknown,
    RETURNING_REQUEST_REQ_PATH_PARAMS
  >({
    requestOptions: {
      method: "patch",
      path: ({ returningId }) => `/returning/${returningId}`,
      apiType: "BofulDefault",
    },

    onSuccess,
  });

  return mutation;
}

function useGetManagerReturningList(params: GET_MANAGER_RETURNING_LIST_REQ) {
  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<typeof RETURNING_QUERY_KEY_GEN.getManagerReturningList>,
    ReturningList
  >({
    queryKey: RETURNING_QUERY_KEY_GEN.getManagerReturningList(params),
    requestOptions: {
      method: "get",
      path: "/returning/list",
      apiType: "BofulDefault",
      params,
    },

    keepPreviousData: true,

    failureModalInfo: {
      customizeMessage: () => ({
        title: `반품 리스트 조회 중에 오류가 발생했습니다.`,
      }),
    },
  });

  return { ...queryResult };
}

function useGetManagerReturningListForInspection(
  params: GET_MANAGER_RETURNING_LIST_FOR_INSPECTION_REQ
) {
  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<
      typeof RETURNING_QUERY_KEY_GEN.getManagerReturningListForInspection
    >,
    ReturningList
  >({
    queryKey:
      RETURNING_QUERY_KEY_GEN.getManagerReturningListForInspection(params),
    requestOptions: {
      method: "get",
      path: "/returning/manager/list/inspection",
      apiType: "BofulDefault",
      params,
    },

    keepPreviousData: true,

    failureModalInfo: {
      customizeMessage: () => ({
        title: `검수 리스트 조회 중에 오류가 발생했습니다.`,
      }),
    },
  });

  return { ...queryResult };
}

function useAssignInspectorToItem() {
  const mutation = useAppMutation<
    unknown,
    ManagerReturningListItem,
    ASSIGN_INSPECTOR_TO_ITEM_REQ_PATH_PARAMS
  >({
    requestOptions: {
      method: "patch",
      path: ({ returningId, itemId }) =>
        `/returning/${returningId}/inspection/assign/${itemId}`,
      apiType: "BofulDefault",
    },

    loadingLabel: "검수 작업자 지정 중",
    failureModalInfo: {
      customizeMessage: () => ({
        title: "검수 담당자 지정 중에 오류가 발생했습니다.",
      }),
    },
  });

  return { ...mutation };
}

function useSetInspectionStarted() {
  const mutation = useAppMutation<
    unknown,
    ManagerReturningListItem,
    SET_INSPECTION_STARTED_REQ_PATH_PARAMS
  >({
    requestOptions: {
      method: "patch",
      path: ({ returningId }) => `/returning/${returningId}/inspection/start`,
      apiType: "BofulDefault",
    },

    loadingLabel: "검수 시작 중",
    failureModalInfo: {
      customizeMessage: () => ({
        title: "검수 시작 중에 오류가 발생했습니다.",
      }),
    },
  });

  return { ...mutation };
}

/**
 * 검수 담당자 정보 및 검수관련 정보 초기화
 */
function useResetInspectorAndInspectionOfItem() {
  const mutation = useAppMutation<
    unknown,
    ManagerReturningListItem,
    RESET_INSPECTOR_AND_INSPECTION_OF_ITEM_REQ_PATH_PARAMS
  >({
    requestOptions: {
      method: "patch",
      path: ({ returningId, itemId }) =>
        `/returning/${returningId}/inspection/reset/${itemId}/worker`,
      apiType: "BofulDefault",
    },

    failureModalInfo: {
      customizeMessage: () => ({
        title: "검수 담당자 삭제 중에 오류가 발생했습니다.",
      }),
    },
  });

  return { ...mutation };
}

/**
 * 검수 담당자를 제외한 검수관련 정보 초기화
 */
function useResetInspectionOfItemExceptInspector() {
  const mutation = useAppMutation<
    unknown,
    ManagerReturningListItem,
    RESET_INSPECTION_OF_ITEM_EXPECT_INSPECTOR_REQ_PATH_PARAMS
  >({
    requestOptions: {
      method: "patch",
      path: ({ returningId, itemId }) =>
        `/returning/${returningId}/inspection/reset/${itemId}`,
      apiType: "BofulDefault",
    },

    failureModalInfo: {
      customizeMessage: () => ({
        title: "검수 카운트 초기화 중에 오류가 발생했습니다.",
      }),
    },
  });

  return { ...mutation };
}

/**
 * 검수 강제 완료처리
 */
function useSetItemInspectionDoneByForce() {
  const mutation = useAppMutation<
    unknown,
    ManagerReturningListItem,
    SET_ITEM_INSPECTION_DONE_BY_FORCE_REQ_PATH_PARAMS
  >({
    requestOptions: {
      method: "patch",
      path: ({ returningId, itemId }) =>
        `/returning/${returningId}/inspection/done/force/${itemId}`,
      apiType: "BofulDefault",
    },

    failureModalInfo: {
      customizeMessage: () => ({
        title: "검수 강제처리 중에 오류가 발생했습니다.",
      }),
    },
  });

  return { ...mutation };
}

/**
 * 검수 완료
 */
function useSetItemInspectionDone() {
  const mutation = useAppMutation<
    SET_ITEM_INSPECTION_DONE_REQ,
    ManagerReturningListItem,
    SET_ITEM_INSPECTION_DONE_REQ_PATH_PARAMS
  >({
    requestOptions: {
      method: "patch",
      path: ({ returningId }) =>
        `/returning/${returningId}/inspection/done/worker`,
      apiType: "BofulDefault",
    },

    failureModalInfo: {
      customizeMessage: () => ({
        title: `검수 완료 중에 오류가 발생했습니다.`,
      }),
    },
  });

  return { ...mutation };
}

/**
 * 검수 담당자 추가
 */
function useAddWorkerToInspection({
  returningId,
  workerId,
}: {
  returningId: number;
  workerId: number;
}) {
  const mutation = useAppMutation<void, ManagerReturningListItem>({
    requestOptions: {
      method: "patch",
      path: `/returning/${returningId}/assign/worker/${workerId}`,
      apiType: "BofulDefault",
    },

    failureModalInfo: {
      customizeMessage: () => ({
        title: "검수 담당자 추가 중에 오류가 발생했습니다.",
      }),
    },
  });

  return { ...mutation };
}

/**
 * 검수 담당자 삭제
 */
function useRemoveWorkerFromInspection({
  returningId,
  workerId,
}: {
  returningId: number;
  workerId: number;
}) {
  const mutation = useAppMutation<void, ManagerReturningListItem>({
    requestOptions: {
      method: "patch",
      path: `/returning/${returningId}/assign/cancel/${workerId}`,
      apiType: "BofulDefault",
    },

    failureModalInfo: {
      customizeMessage: () => ({
        title: "검수 담당자에서 제외시키는 중에 오류가 발생했습니다.",
      }),
    },
  });

  return { ...mutation };
}

/**
 * 검수 마감
 */
function useSetInspectionDone({
  returningId,
  successModalInfo,
}: {
  returningId: number;
  successModalInfo?: QueryResponseHandlerSuccessModalInfo<ManagerReturningListItem>;
}) {
  const mutation = useAppMutation<
    SET_INSPECTION_DONE_REQ,
    ManagerReturningListItem
  >({
    requestOptions: {
      method: "patch",
      path: `/returning/${returningId}/inspection/done`,
      apiType: "BofulDefault",
    },

    successModalInfo,

    failureModalInfo: {
      customizeMessage: () => ({
        title: "검수 마감 중에 오류가 발생했습니다.",
      }),
    },
  });

  return { ...mutation };
}

/**
 * 검수 문제보고
 */
function useReportInspectionProblem({ returningId }: { returningId: number }) {
  const mutation = useAppMutation<
    REPORT_INSPECTION_PROBLEM_REQ,
    ManagerReturningListItem
  >({
    requestOptions: {
      method: "patch",
      path: `/returning/${returningId}/report/inspection `,
      apiType: "BofulDefault",
    },

    failureModalInfo: {
      customizeMessage: () => ({
        title: "검수 문제보고 중에 오류가 발생했습니다.",
      }),
    },
  });

  return { ...mutation };
}

/**
 * 특정 SKU에 대한 입고담당자 할당(분할입고 포함)
 */
function useAssignPlacerToItem() {
  const mutation = useAppMutation<
    ASSIGN_PLACER_TO_ITEM_REQ,
    ManagerReturningListItem,
    ASSIGN_PLACER_TO_ITEM_REQ_PATH_PARAMS
  >({
    requestOptions: {
      method: "patch",
      path: ({ returningId, itemId }) =>
        `/returning/${returningId}/putAway/assign/${itemId}`,
      apiType: "BofulDefault",
    },

    failureModalInfo: {
      customizeMessage: () => ({
        title: "입고 담당자 지정 중에 오류가 발생했습니다.",
      }),
    },
  });

  return { ...mutation };
}

/**
 * 입고/분할입고에 대한 담당자 정보 및 입고관련 정보 초기화
 */
function useResetPlacerAndWarehousingOfItem() {
  const mutation = useAppMutation<
    RESET_PLACER_AND_WAREHOUSING_OF_ITEM_REQ,
    ManagerReturningListItem,
    RESET_PLACER_AND_WAREHOUSING_OF_ITEM_REQ_PATH_PARAMS
  >({
    requestOptions: {
      method: "patch",
      path: ({ returningId, itemId }) =>
        `/returning/${returningId}/putAway/reset/${itemId}/worker`,
      apiType: "BofulDefault",
    },

    failureModalInfo: {
      customizeMessage: () => ({
        title: "입고 담당자 삭제 중에 오류가 발생했습니다.",
      }),
    },
  });

  return { ...mutation };
}

/**
 * 입고/분할입고에 대한 담당자를 제외한 입고관련 정보 초기화
 */
function useResetWarehousingOfItemExceptPlacer() {
  const mutation = useAppMutation<
    RESET_WAREHOUSING_OF_ITEM_EXPECT_PLACER_REQ,
    ManagerReturningListItem,
    RESET_WAREHOUSING_OF_ITEM_EXPECT_PLACER_REQ_PARAMS
  >({
    requestOptions: {
      method: "patch",
      path: ({ returningId, itemId }) =>
        `/returning/${returningId}/putAway/reset/${itemId}`,
      apiType: "BofulDefault",
    },

    failureModalInfo: {
      customizeMessage: () => ({
        title: "입고 카운트 초기화 중에 오류가 발생했습니다.",
      }),
    },
  });

  return { ...mutation };
}

/**
 * 입고 강제 완료처리
 */
function useSetItemWarehousingDoneByForce() {
  const mutation = useAppMutation<
    unknown,
    ManagerReturningListItem,
    SET_ITEM_WAREHOUSING_DONE_BY_FORCE_REQ_PATH_PARAMS
  >({
    requestOptions: {
      method: "patch",
      path: ({ returningId, itemId }) =>
        `/returning/${returningId}/putawayItem/force/${itemId}`,
      apiType: "BofulDefault",
    },

    failureModalInfo: {
      customizeMessage: () => ({
        title: "입고 강제처리 중에 오류가 발생했습니다.",
      }),
    },
  });

  return { ...mutation };
}

/**
 * 입고/분할입고 완료
 */
function useSetItemWarehousingDone({
  locationType,
  selectedLocationBarcode,
}: {
  locationType: "single" | "multi";
  selectedLocationBarcode: string;
}) {
  const mutation = useAppMutation<
    SET_ITEM_WAREHOUSING_DONE_REQ,
    ManagerReturningListItem,
    SET_ITEM_WAREHOUSING_DONE_REQ_PATH_PARAMS
  >({
    requestOptions: {
      method: "patch",
      path: ({ returningId }) => `/returning/${returningId}/putawayItem`,
      apiType: "BofulDefault",
    },

    failureModalInfo: {
      customizeMessage: (failureInfo) =>
        failureInfo?.code === 400 && failureInfo.errorCode === "E3019"
          ? {
              messageType: "titleOnly",
              title: (
                <div style={{ color: COLOR.pointWarning }}>
                  해당 위치({selectedLocationBarcode})에는 이미 입고된 상품이
                  있습니다.
                  <br />
                  다른 위치에 입고해주세요.
                </div>
              ),
            }
          : {
              title: `${
                locationType === "single" ? "입고 완료" : "분할입고 완료"
              } 중에 오류가 발생했습니다.`,
            },
    },
  });

  return { ...mutation };
}

/**
 * 분할입고 내역을 추가
 * 기존에 placeItems가 한 개 뿐이면서 quantity가 max인 경우 (사실상 단일입고),
 * 새 분할입고를 추가하면 기존 placeItems는 사라지고 새로 추가한 것만 남는다.
 */
function useAddPartialWarehousing({
  type,
  returningId,
}: {
  type: "add" | "AfterRemove";
  returningId: number;
}) {
  const mutation = useAppMutation<
    ADD_PARTIAL_WAREHOUSING_REQ,
    ManagerReturningListItem
  >({
    requestOptions: {
      method: "patch",
      path: `/returning/${returningId}/add/partial`,
      apiType: "BofulDefault",
    },

    failureModalInfo: {
      customizeMessage: () => ({
        title: `${
          type === "add"
            ? "분할입고 추가 중에 오류가 발생했습니다."
            : "일반입고 생성 중에 오류가 발생했습니다."
        } `,
      }),
    },
  });

  return { ...mutation };
}

/**
 * 분할입고 내역을 삭제
 */
function useRemovePartialWarehousing() {
  const mutation = useAppMutation<
    REMOVE_PARTIAL_WAREHOUSING_REQ,
    ManagerReturningListItem,
    REMOVE_PARTIAL_WAREHOUSING_REQ_PATH_PARAMS
  >({
    requestOptions: {
      method: "delete",
      path: ({ returningId, itemId }) =>
        `/returning/${returningId}/delete/partial/${itemId}`,
      apiType: "BofulDefault",
    },

    failureModalInfo: {
      customizeMessage: () => ({
        title: "분할입고 내역 삭제 중에 오류가 발생했습니다.",
      }),
    },
  });

  return { ...mutation };
}

/**
 * 입고 마감
 */
function useSetWarehousingDone({
  returningId,
  successModalInfo,
}: {
  returningId: number;
  successModalInfo?: QueryResponseHandlerSuccessModalInfo<ManagerReturningListItem>;
}) {
  const mutation = useAppMutation<
    SET_WAREHOUSING_DONE_REQ,
    ManagerReturningListItem
  >({
    requestOptions: {
      method: "patch",
      path: `/returning/${returningId}/done`,
      apiType: "BofulDefault",
    },

    successModalInfo,

    failureModalInfo: {
      customizeMessage: () => ({
        title: "입고 마감 중에 오류가 발생했습니다.",
      }),
    },
  });

  return { ...mutation };
}

/**
 * 입고 문제보고
 */
function useReportWarehousingProblem({ returningId }: { returningId: number }) {
  const mutation = useAppMutation<
    REPORT_WAREHOUSING_PROBLEM_REQ,
    ManagerReturningListItem
  >({
    requestOptions: {
      method: "patch",
      path: `/returning/${returningId}/report/returning`,
      apiType: "BofulDefault",
    },

    failureModalInfo: {
      customizeMessage: () => ({
        title: "입고 문제보고 중에 오류가 발생했습니다.",
      }),
    },
  });

  return { ...mutation };
}

/**
 * My의뢰로 이동
 */
function useAssignToMe({
  itemId,
  successModalInfo,
}: {
  itemId: number;
  successModalInfo: QueryResponseHandlerSuccessModalInfo<ManagerReturningListItem>;
}) {
  const mutation = useAppMutation<void, ManagerReturningListItem>({
    requestOptions: {
      method: "patch",
      path: `/returning/${itemId}/assignToMe`,
      apiType: "BofulDefault",
    },

    successModalInfo,

    failureModalInfo: {
      customizeMessage: () => ({
        title: "My의뢰로 이동시키는 중에 오류가 발생했습니다.",
      }),
    },
  });

  return { ...mutation };
}

/**
 * PDA > 송장 스캔 완료
 */
function useSetInvoiceScanDone({
  returningId,
  successModalInfo,
}: {
  returningId: number;
  successModalInfo: QueryResponseHandlerSuccessModalInfo<ManagerReturningListItem>;
}) {
  const mutation = useAppMutation<void, ManagerReturningListItem>({
    requestOptions: {
      method: "patch",
      path: `/returning/${returningId}/scanInvoice/done`,
      apiType: "BofulDefault",
    },

    successModalInfo,

    failureModalInfo: {
      customizeMessage: () => ({
        title: "송장 스캔 완료 중에 오류가 발생했습니다.",
      }),
    },
  });

  return { ...mutation };
}

/**
 * PDA > 송장확인과 관련된 정보(isInvoiceMatched, 송장 사진)를 초기화
 */
function useResetInvoiceInfo({ returningId }: { returningId: number }) {
  const mutation = useAppMutation<void, ManagerReturningListItem>({
    requestOptions: {
      method: "patch",
      path: `/returning/${returningId}/scanInvoice/reset`,
      apiType: "BofulDefault",
    },

    failureModalInfo: {
      customizeMessage: () => ({
        title: "송장 스캔 초기화 중에 오류가 발생했습니다.",
      }),
    },
  });

  return { ...mutation };
}

/**
 * PDA > 제품 수령 완료
 */
function useSetArrived({ returningId }: { returningId: number }) {
  const mutation = useAppMutation<void, ManagerReturningListItem>({
    requestOptions: {
      method: "patch",
      path: `/returning/${returningId}/arrive`,
      apiType: "BofulDefault",
    },

    failureModalInfo: {
      customizeMessage: () => ({
        title: "제품 수령 완료 중에 오류가 발생했습니다.",
      }),
    },
  });

  return { ...mutation };
}

function useRequestForPickupUncollected(
  sideEffectOptions?: MutationSideEffectType<unknown, unknown>
) {
  const mutation = useAppMutation<unknown, unknown, { returningId: number }>({
    requestOptions: {
      method: "patch",
      path: ({ returningId }) => `/returning/${returningId}/uncollected`,
      apiType: "BofulDefault",
    },
    ...sideEffectOptions,

    failureModalInfo: {
      customizeMessage: () => ({
        title: "반품수거 재요청 중 오류가 발생했습니다.",
      }),
    },
  });

  return { ...mutation };
}

function useSubmitReturningInvoiceNo({
  handleConfirmSuccess,
  sideEffectOptions,
}: { handleConfirmSuccess: () => void } & {
  sideEffectOptions?: MutationSideEffectType<
    SUBMIT_RETURNING_INVOICE_NO_REQ,
    SUBMIT_RETURNING_INVOICE_NO_RES
  >;
}) {
  const mutation = useAppMutation<
    SUBMIT_RETURNING_INVOICE_NO_REQ,
    SUBMIT_RETURNING_INVOICE_NO_RES,
    { returningId: number }
  >({
    requestOptions: {
      method: "patch",
      path: ({ returningId }) => `/returning/${returningId}/invoiceNo`,
      apiType: "BofulDefault",
    },

    ...sideEffectOptions,

    successModalInfo: {
      customizeMessage: () => {
        return { title: "등록 완료되었습니다." };
      },
      handleConfirmSuccess: (initQuery) => {
        initQuery();

        handleConfirmSuccess();
      },
    },

    failureModalInfo: {
      customizeMessage: () => ({
        title: "반품 송장번호 입력 중 오류가 발생했습니다.",
      }),
    },
  });

  return { ...mutation };
}

function useCancelReturning(
  {
    handleConfirmSuccess,
  }: {
    handleConfirmSuccess: () => void;
  },
  sideEffectOptions?: MutationSideEffectType<CANCEL_RETURNING_REQ, unknown>
) {
  const mutation = useAppMutation<CANCEL_RETURNING_REQ, unknown>({
    requestOptions: {
      method: "patch",
      path: "/returning/cancel",
      apiType: "BofulDefault",
    },
    ...sideEffectOptions,

    successModalInfo: {
      customizeMessage: () => {
        return { title: "취소 완료 되었습니다." };
      },

      handleConfirmSuccess: (initQuery) => {
        initQuery();
        handleConfirmSuccess();
      },
    },

    failureModalInfo: {
      customizeMessage: () => {
        return { title: "취소에 실패했습니다." };
      },
    },
  });

  return { ...mutation };
}

function useGetReturningDetail(
  sideEffectOptions?: MutationSideEffectType<unknown, unknown>
) {
  const mutation = useAppMutation<
    unknown,
    GET_RETURNING_INPROGRESS_DETAIL_RES,
    { returningId: number }
  >({
    requestOptions: {
      method: "get",
      path: ({ returningId }) => `/returning/list/${returningId}`,
      apiType: "BofulDefault",
    },
    ...sideEffectOptions,
  });

  return { ...mutation };
}

function useGetAdminReturningList({
  enabled,
  ...params
}: GET_ADMIN_RETURNING_LIST_REQ & { enabled?: boolean }) {
  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<typeof RETURNING_QUERY_KEY_GEN.getAdminReturningList>,
    GET_ADMIN_RETURNING_LIST_RES
  >({
    queryKey: RETURNING_QUERY_KEY_GEN.getAdminReturningList(params),
    requestOptions: {
      method: "get",
      path: "/returning/admin/list",
      apiType: "BofulDefault",
      params,
    },

    ...(typeof enabled === "boolean" ? { enabled } : {}),

    keepPreviousData: true,
  });

  return { ...queryResult };
}

function useGetAdminReturningListForCSVDownload({
  enabled,
  ...params
}: GET_ADMIN_RETURNING_LIST_FOR_CSV_DOWNLOAD_REQ & {
  enabled?: boolean;
}) {
  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<
      typeof RETURNING_QUERY_KEY_GEN.getAdminReturningListForCSVDownload
    >,
    GET_ADMIN_RETURNING_LIST_FOR_CSV_DOWNLOAD_RES
  >({
    queryKey:
      RETURNING_QUERY_KEY_GEN.getAdminReturningListForCSVDownload(params),
    requestOptions: {
      method: "get",
      path: "/returning/admin/list",
      apiType: "BofulDefault",
      params,
    },

    ...(typeof enabled === "boolean" ? { enabled } : {}),
  });

  return { ...queryResult };
}

function useGetAdminReturningDetail({
  enabled,
  ...params
}: GET_ADMIN_RETURNING_DETAIL_REQ & { enabled?: boolean }) {
  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<typeof RETURNING_QUERY_KEY_GEN.getAdminReturningDetail>,
    GET_ADMIN_RETURNING_DETAIL_RES
  >({
    queryKey: RETURNING_QUERY_KEY_GEN.getAdminReturningDetail(params),
    requestOptions: {
      method: "get",
      path: `/returning/admin/${params.returningId}`,
      apiType: "BofulDefault",
    },

    ...(typeof enabled === "boolean" ? { enabled } : {}),
  });

  return { ...queryResult };
}

function useSetAdminProcessStatusToDisposal(
  sideEffectOptions?: MutationSideEffectType<unknown, unknown>
) {
  const mutation = useAppMutation<
    unknown,
    unknown,
    SET_ADMIN_PROCESS_STATUS_TO_DISPOSAL_REQ_PATH_PARAMS
  >({
    requestOptions: {
      method: "patch",
      path: ({ returningId }) => `/returning/admin/${returningId}/disposal`,
      apiType: "BofulDefault",
    },
    ...sideEffectOptions,
  });

  return { ...mutation };
}

function useSetAdminProcessStatusToRestock(
  sideEffectOptions?: MutationSideEffectType<unknown, unknown>
) {
  const mutation = useAppMutation<
    unknown,
    unknown,
    SET_ADMIN_PROCESS_STATUS_TO_RESTOCK_REQ_PATH_PARAMS
  >({
    requestOptions: {
      method: "patch",
      path: ({ returningId }) => `/returning/admin/${returningId}/restock`,
      apiType: "BofulDefault",
    },
    ...sideEffectOptions,
  });

  return { ...mutation };
}

function useSetAdminProcessStatusToForward(
  sideEffectOptions?: MutationSideEffectType<unknown, unknown>
) {
  const mutation = useAppMutation<
    unknown,
    unknown,
    SET_ADMIN_PROCESS_STATUS_TO_FORWARD_REQ_PATH_PARAMS
  >({
    requestOptions: {
      method: "patch",
      path: ({ returningId }) => `/returning/admin/${returningId}/forward`,
      apiType: "BofulDefault",
    },
    ...sideEffectOptions,
  });

  return { ...mutation };
}

function useGetReturningInprogressList(
  params: GET_RETURNING_INPROGRESS_LIST_REQ
) {
  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<typeof RETURNING_QUERY_KEY_GEN.getReturningInprogressList>,
    GET_RETURNING_INPROGRESS_LIST_RES
  >({
    queryKey: RETURNING_QUERY_KEY_GEN.getReturningInprogressList(params),
    requestOptions: {
      method: "get",
      path: `/returning/user/list`,
      apiType: "BofulDefault",
      params,
    },

    failureModalInfo: {
      customizeMessage: () => ({
        title: "반품 진행내역 리스트 조회 중에 오류가 발생했습니다.",
      }),
    },
  });

  return queryResult;
}

function useGetReturningInprogressListForCSVDownload({
  enabled,
  ...params
}: GET_RETURNING_INPROGRESS_LIST_FOR_CSV_DOWNLOAD_REQ & {
  enabled?: boolean;
}) {
  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<
      typeof RETURNING_QUERY_KEY_GEN.getReturningInprogressListForCSVDownload
    >,
    GET_RETURNING_INPROGRESS_LIST_FOR_CSV_DOWNLOAD_RES
  >({
    queryKey:
      RETURNING_QUERY_KEY_GEN.getReturningInprogressListForCSVDownload(params),
    requestOptions: {
      method: "get",
      path: `/returning/user/list`,
      apiType: "BofulDefault",
      params,
    },

    ...(typeof enabled === "boolean" ? { enabled } : {}),
  });

  return queryResult;
}

function useAddReturningInvoice({
  returningId,
}: {
  returningId: number | undefined;
}) {
  const mutation = useAppMutation<unknown, ReturningListItem>({
    requestOptions: {
      method: "patch",
      path: `/returning/admin/${returningId}/invoice`,
      apiType: "BofulDefault",
    },

    failureModalInfo: {
      customizeMessage: () => ({
        title: "반송장 추가 중에 오류가 발생했습니다.",
      }),
    },
  });

  return mutation;
}

function useCancelReturningInvoice({
  returningId,
}: {
  returningId: number | undefined;
}) {
  const mutation = useAppMutation<
    unknown,
    ReturningListItem,
    { packingId: number }
  >({
    requestOptions: {
      method: "patch",
      path: ({ packingId }) =>
        `/returning/admin/${returningId}/invoice/cancel/${packingId}`,
      apiType: "BofulDefault",
    },

    failureModalInfo: {
      customizeMessage: () => ({
        title: "반송장 취소 중에 오류가 발생했습니다.",
      }),
    },
  });

  return mutation;
}

function useEditSenderDetail({
  returningId,
}: {
  returningId: number | undefined;
}) {
  const mutation = useAppMutation<EDIT_SENDER_DETAIL_REQ, unknown>({
    requestOptions: {
      method: "patch",
      path: `/returning/admin/${returningId}`,
      apiType: "BofulDefault",
    },

    failureModalInfo: {
      customizeMessage: () => ({
        title: "반품 배송 정보 수정 중에 오류가 발생했습니다.",
      }),
    },
  });

  return mutation;
}

function useGetPDAReturningDetail({
  id,
  onSuccess,
  titleOfFailureModal,
}: {
  id: number;
  onSuccess?: (res: GET_PDA_RETURNING_LIST_RES) => void;
  titleOfFailureModal?: string;
}) {
  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<typeof RETURNING_QUERY_KEY_GEN.getPDAReturningDetail>,
    GET_PDA_RETURNING_LIST_RES
  >({
    queryKey: RETURNING_QUERY_KEY_GEN.getPDAReturningDetail({ id }),
    requestOptions: {
      method: "get",
      path: `/returning/list/pda/${id}`,
      apiType: "BofulDefault",
    },

    onSuccess,

    failureModalInfo: {
      customizeMessage: (failureInfo) => {
        return {
          title:
            titleOfFailureModal ?? "반품 상세 조회 중에 오류가 발생했습니다.",
        };
      },
    },
  });

  return { ...queryResult };
}

// web용 검수문제 조회 api
function useGetReturningInspectDetail({
  id,
  onSuccess,
  titleOfFailureModal,
  enabled,
}: {
  id: number;
  onSuccess?: (res: GET_RETURNING_INSPECT_DETAIL_RES) => void;
  titleOfFailureModal?: string;
  enabled?: boolean;
}) {
  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<typeof RETURNING_QUERY_KEY_GEN.getReturningInspectDetail>,
    GET_RETURNING_INSPECT_DETAIL_RES
  >({
    queryKey: RETURNING_QUERY_KEY_GEN.getReturningInspectDetail({ id }),
    requestOptions: {
      method: "get",
      path: `/returning/list/web/${id}`,
      apiType: "BofulDefault",
    },

    enabled,

    onSuccess,

    failureModalInfo: {
      customizeMessage: (failureInfo) => {
        return {
          title:
            titleOfFailureModal ?? "검수 정보 조회 중 오류가 발생했습니다.",
        };
      },
    },
  });

  return { ...queryResult };
}

function useUpdateAgreementForInspection({
  returningId,
  onSuccess,
}: {
  returningId: number;
  onSuccess?: (
    res: UPDATE_AGREEMENT_FOR_INSPECTION_RES,
    req: UPDATE_AGREEMENT_FOR_INSPECTION_REQ
  ) => void;
}) {
  const mutation = useAppMutation<
    UPDATE_AGREEMENT_FOR_INSPECTION_REQ,
    UPDATE_AGREEMENT_FOR_INSPECTION_RES
  >({
    requestOptions: {
      method: "patch",
      path: `/returning/${returningId}/agree`,
      apiType: "BofulDefault",
    },

    onSuccess,

    failureModalInfo: {
      customizeMessage: () => ({
        title: "반품 검수 동의 중 오류가 발생했습니다.",
      }),
    },
  });

  return mutation;
}

function useUpdateManagementDateOfUnverifiedItem({
  returningId,
  onSuccess,
}: {
  returningId: number;
  onSuccess?: (res: UPDATE_MANAGEMENT_DATE_OF_UNVERIFIED_ITEM_RES) => void;
}) {
  const mutation = useAppMutation<
    UPDATE_MANAGEMENT_DATE_OF_UNVERIFIED_ITEM_REQ,
    UPDATE_MANAGEMENT_DATE_OF_UNVERIFIED_ITEM_RES
  >({
    requestOptions: {
      method: "patch",
      path: `/returning/${returningId}/unverified/managementDate`,
      apiType: "BofulDefault",
    },

    onSuccess,

    failureModalInfo: {
      customizeMessage: () => ({
        title: "불일치 상품의 관리일자 업데이트 중 오류가 발생했습니다.",
      }),
    },
  });

  return mutation;
}

const RETURNING_QUERY = {
  useGetReturningRequestList,
  useGetReturningRequestListForCSVDownload,

  useRequestReturning,
  useUpdateRequestReturning,

  useGetManagerReturningList,
  useGetManagerReturningListForInspection,
  useAssignInspectorToItem,
  useSetInspectionStarted,
  useResetInspectorAndInspectionOfItem,
  useResetInspectionOfItemExceptInspector,
  useSetItemInspectionDoneByForce,
  useSetItemInspectionDone,
  useAddWorkerToInspection,
  useRemoveWorkerFromInspection,
  useSetInspectionDone,
  useReportInspectionProblem,

  useAssignPlacerToItem,
  useResetPlacerAndWarehousingOfItem,
  useResetWarehousingOfItemExceptPlacer,
  useSetItemWarehousingDoneByForce,
  useSetItemWarehousingDone,
  useAddPartialWarehousing,
  useRemovePartialWarehousing,
  useSetWarehousingDone,
  useReportWarehousingProblem,

  useAssignToMe,
  useSetInvoiceScanDone,
  useResetInvoiceInfo,
  useSetArrived,
  useRequestForPickupUncollected,
  useSubmitReturningInvoiceNo,
  useCancelReturning,
  useGetReturningDetail,

  useGetAdminReturningList,
  useGetAdminReturningListForCSVDownload,
  useGetAdminReturningDetail,

  useSetAdminProcessStatusToDisposal,
  useSetAdminProcessStatusToRestock,
  useSetAdminProcessStatusToForward,

  useGetReturningInprogressList,
  useGetReturningInprogressListForCSVDownload,

  useAddReturningInvoice,
  useCancelReturningInvoice,

  useEditSenderDetail,

  useGetPDAReturningDetail,
  useGetReturningInspectDetail,

  useUpdateAgreementForInspection,

  useUpdateManagementDateOfUnverifiedItem,
};

export default RETURNING_QUERY;
